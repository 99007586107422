.gameBG {
    background: #000;
    height: calc(100% - 50px);
    width: 100%;
}

#game-page {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.more-games .hot-games-tile {
    height: 200px;
    display: block;
}

.more-games-content{
    min-height: 230px;
}

.more-games-content .game-row {
    justify-content: space-evenly !important;
}