html::-webkit-scrollbar {
    display: none;
}

.modal-open {
    padding: 0 !important;
}

/* ////john csss theme v3// */
@font-face {
    font-family: "poppins-regular";
    src: url(../fonts/Poppins-Regular.ttf);
}

@font-face {
    font-family: "poppins-medium";
    src: url(../fonts/Poppins-Medium.ttf);
}

@font-face {
    font-family: "poppins-semibold";
    src: url(../fonts/Poppins-SemiBold.ttf);
}

@font-face {
    font-family: "poppins-bold";
    src: url(../fonts/Poppins-Bold.ttf);
}

@font-face {
    font-family: "poppins-black";
    src: url(../fonts/Poppins-Black.ttf);
}

@font-face {
    font-family: "Montserrat";
    src: url(../fonts/Montserrat-Regular.ttf);
    font-weight: 400;
}

@font-face {
    font-family: "Montserrat";
    src: url(../fonts/Montserrat-Bold.ttf);
    font-weight: bold;
}

@font-face {
    font-family: "Montserrat";
    src: url(../fonts/Montserrat-Bold.ttf);
    font-weight: 700;
}

body {
    font-family: 'Montserrat';
    color: #fff;
}

h3 {
    font-size: 18px;
    font-family: Montserrat;
}

h4 {
    font-size: 16px;
    font-weight: 600;
    font-family: "Montserrat";
}

.font11 {
    font-size: 11px;
}

.font12 {
    font-size: 12px;
}

.font14 {
    font-size: 14px;
}

.font16 {
    font-size: 16px;
}

.font18 {
    font-size: 1px;
}

.font20 {
    font-size: 20px;
}

.font24 {
    font-size: 24px;
}

.img-resposive {
    max-width: 100%;
}

.img-100 {
    width: 100%;
}

.color-gray {
    color: #A4A4A4;
}

.color-red {
    color: #FF334B;
}

.color-green {
    background: linear-gradient(0deg, rgba(164, 164, 164, 0.2), rgba(164, 164, 164, 0.2)), linear-gradient(318.37deg, rgba(50, 249, 0, 0.8) 0%, rgba(20, 239, 199, 0.8) 94.77%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.color-mainBlue {
    color: #3369FF;
}

.color-yellow {
    color: #FFD928;
}

.all-background {
    min-height: 100vh;
    /* background-image: url("../../assets/img/v3/pink.png"), url("../../assets/img/v3/background.png");
    background-position: 0px 0px, center;
    background-size: 125%, cover;
    background-repeat: no-repeat, no-repeat; */
    background: #0D1A3B;
    padding: 0 20px;
}

input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #A4A4A4 !important;
    opacity: 1; /* Firefox */
}

input:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #A4A4A4 !important;
}

input::-ms-input-placeholder { /* Microsoft Edge */
    color: #A4A4A4 !important;
}
/* /////end of general */
.provider-container .css-16hr6bx-control {
    /* background-image: ; */
    background: url("../../assets/img/v3/arrow-down.png");
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    border: 0px;
    width: 100%;
    font-size: 14px;
    color: #A4A4A4;
    height: 50px;
    padding: 0px 20px 0px;
    outline: unset;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: #132144 !important;
    background-repeat: no-repeat !important;
    background-position: right 20px center !important;
}

.provider-container .css-bbcqiw-control {
    /* background-image: ; */
    background: url("../../assets/img/v3/arrow-down.png");
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    border: 0px;
    width: 100%;
    font-size: 14px;
    color: #A4A4A4;
    height: 50px;
    padding: 0px 20px 0px;
    outline: unset;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: #132144 !important;
    background-repeat: no-repeat !important;
    background-position: right 20px center !important;
}

.provider-container .css-cshxy6-placeholder {
    font-size: 14px;
    color: #A4A4A4;
    margin: 0px;
    padding: 0px;
}

.provider-container .css-1bxi0x5-singleValue {
    font-size: 14px;
    color: #A4A4A4;
    margin: 0px;
    padding: 0px;
}

.provider-container .css-g1d714-ValueContainer {
    font-size: 14px;
    color: #A4A4A4;
    margin: 0px;
    padding: 0px;
}

.provider-container .css-tlfecz-indicatorContainer {
    display: none;
}

.provider-container .css-1okebmr-indicatorSeparator {
    display: none;
}

.deposit-form {
    margin: 0px;
}

.provider-container .css-1gtu0rj-indicatorContainer {
    display: none;
}

.btn.btn-brand-gradient {
    background: #3369FF;
    box-shadow: 0px 5px 30px rgba(51, 105, 255, 0.7);
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    font-size: 18px;
    font-family: "poppins-semibold";
    color: #fff;
    width: 100% !important;
}

.reminder {
    color: #FF334B;
    margin-top: 10px;
    display: block;
}

.deposit-table .bank-text {
    padding-left: unset !important;
    padding-right: unset !important;
}

.deposit-container .deposit-table .border-top.border-bottom {
    border-bottom: 1px solid #D9D9D91A !important;
}

.bank-text.img-col#img-col-id {
    max-width: 50px;
    word-wrap: break-word;
    border-bottom: 1px solid #D9D9D91A !important;
}

.bank-img {
    width: 50px;
    height: auto;
}

.deposit-container {
/*    background: linear-gradient(0deg, rgba(51, 105, 255, 0.1), rgba(51, 105, 255, 0.1)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%);*/
    background: rgba(255, 255, 255, 0.02);
    border-radius: 8px;
    box-shadow: 0 4px 40px rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    padding: 15px 10px;
    margin-bottom: 24px;
/*    border: 1px solid rgba(255, 255, 255, 1);*/
}

.deposit-container .deposit-table {
    border: none;
}

.deposit-table .custom-checkbox input[type="radio"] {
    background: unset !important;
    border: 2px solid #898F97 !important;
    padding: 0px;
}

.deposit-table .custom-checkbox input[type="radio"]:checked {
    background-color: #3369FF !important;
    border: 2px solid #3369FF !important;
}

.deposit.input-group .form-control {
    border-radius: 8px 0px 0px 8px !important;
}

.deposit .close-button {
    background-color: #132144 !important;
    border-radius: 0px 8px 8px 0px !important;
    display: flex;
    align-items: center;
}

.amount-row {
    margin-bottom: 24px !important;
    padding-bottom: 0px;
}

.dropzone {
    background: transparent !important;
    border: 1px dashed #3369FF !important;
    filter: drop-shadow(0px 4px 7px rgba(0, 0, 0, 0.3));
    border-radius: 8px;
    min-height: 115px !important;
}

    .dropzone h4 {
        font-size: 12px;
        color: #898F97;
        font-weight: 400;
    }

.deposit-table tr:nth-child(n+1) td:first-child {
    background-color: #D4E6FF;
    vertical-align: middle;
}

.deposit-table tr:nth-child(n+1) td:first-child input {
    margin-left: -6px;
}

.deposit-container .deposit-table td {
    color: #ffffff;
    background-color: transparent !important;
    border: unset !important;
    padding: 8px 0;
    font-size: 11px !important;
    font-weight: 300;
    vertical-align: middle;
}

.deposit-container .deposit-table > tbody > tr:nth-child(2n+0) {
    border-bottom: 1px solid #D9D9D91A !important;
}

.deposit-container .deposit-table > tbody > tr:nth-child(2n+0) > td.border-top, 
.deposit-container .deposit-table > tbody > tr:nth-child(2n+0) > td.bank-text {
    border-bottom: 1px solid #D9D9D91A !important;
}

.deposit-container .deposit-table > tbody > tr:last-child > td.border-top,
.deposit-container .deposit-table > tbody > tr:last-child > td.bank-text {
    border-radius: 0;
}

.deposit-container .deposit-table > tbody > tr .float-right-icon {
    background: rgba(255, 255, 255, 0.02);
    border-radius: 8px;
    box-shadow: 0 3px 12px rgba(0, 0, 0, 24%);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    padding: 8px;
    border-radius: 50%;
    color: #3369FF;
    font-weight: 700;
}

/*.deposit-table td:nth-child(n+1) {
    background-color: #D4E6FF
}

.deposit-table tr:first-child td:first-child {
    border-top-left-radius: 8px;
}

.deposit-table tr:first-child td:last-child {
    border-top-right-radius: 8px;
}

.deposit-table tr:last-child td:first-child {
    border-bottom-left-radius: 8px;
}

.deposit-table tr:last-child td:last-child {
    border-bottom-right-radius: 8px;
}*/
.welcome-container {
    min-height: 100vh;
    background-image: url("../../assets/img/v3/background.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    flex: 1;
}

    .welcome-container > div:first-child {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .welcome-container > div:last-child {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

.welcome-label {
    color: #EBEBF599;
    font-weight: 400;
}

.custom-select-container {
    position: relative;
    background: transparent;
    border: 0px;
    outline: unset;
    color: #A4A4A4;
}

.custom-select-container-1 {
    position: relative;
    background: transparent;
    border: 0px;
    outline: unset;
    color: #A4A4A4;
    background-color: #132144 !important;
    box-shadow: rgba(0, 0, 0, 0.3);
}

.custom-select-field {
    background: linear-gradient(0deg, rgba(25, 35, 47, 0.2), rgba(25, 35, 47, 0.2)), linear-gradient(271.33deg, rgba(78, 75, 200, 0.3) 0%, rgba(254, 94, 171, 0.03) 100%);
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    color: #EBEBF599;
    font-size: 14px;
    padding: 10px 20px;
    line-height: 1;
    min-width: 200px;
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

    .custom-select-field > div:first-child {
        display: flex;
        align-items: center;
        column-gap: 10px;
    }

.country-dropdown-container {
    background-color: #31376e;
    /* background: linear-gradient(0deg, rgba(25, 35, 47, 0.2), rgba(25, 35, 47, 0.2)), linear-gradient(271.33deg, rgba(78, 75, 200, 0.3) 0%, rgba(254, 94, 171, 0.03) 100%); */
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    color: #EBEBF599;
    font-size: 14px;
    position: absolute;
    width: 100%;
    z-index: 1;
}

.country-dropdown-items {
    display: flex;
    align-items: center;
    column-gap: 10px;
    padding: 3px 20px;
}

.welcome-version {
    margin: 3em 0px 3em;
}

.flex-container-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.banner-slick.main-banners {
    margin: 0px -20px;
}
.banner-slick .slick-dots{
    bottom:15%;
}
.banner-slick .slick-dots li button:before{
    display: none;
}
.banner-slick .slick-dots li button{
    background-color: #fff;
    width: 6px;
    height: 6px;
    border-radius: 100%;
    padding: 0px;
}
.banner-slick .slick-dots li{
    width: unset;
    height: unset;
}
.banner-slick .slick-dots li.slick-active button{
    background-color: #FFBA33;
    width: 20px;
    border-radius: 6px;
}
.banner-slick.sub-banners .slick-list {
    border-radius: 20px;
}
.home-announcement{
    display: flex;
    align-items: center;
    font-size: 11px;
    line-height: 1;
    column-gap: 15px;
    margin-top: 10px !important;
}

.login-register-btn-container {
    display: flex;
    column-gap: 15px;
    align-items: center;
}

    .login-register-btn-container > div {
        width: 100%;
    }

.login-btn {
    background: linear-gradient(180deg, #3369FF 0%, rgba(0, 0, 0, 0.4) 133.33%);
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.25);
    box-sizing: border-box;
    border-radius: 8px;
    font-size: 14px;
    text-align: center;
    display: block;
    padding: 14px 10px;
    color: #ffffffb4;
    font-size: 14px;
    font-family: "poppins-medium";
    text-transform: unset;
    cursor: pointer;
}

.register-btn {
    background: linear-gradient(180deg, #FF334B 0%, rgba(0, 0, 0, 0.1) 173.08%);
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.25);
    box-sizing: border-box;
    border-radius: 8px;
    font-size: 14px;
    text-align: center;
    display: block;
    padding: 14px 10px;
    color: #ffffffb4;
    font-size: 14px;
    font-family: "poppins-semibold";
    text-transform: unset;
    cursor: pointer;
}

.under-title-text {
    color: #898F97;
    font-size: 11px;
}

.home-games-categories {
    display: flex;
    align-items: center;
    column-gap: 20px;
    max-width: 500px;
}

    .home-games-categories > div > div {
        margin-bottom: 10px;
        position: relative;
        opacity: .6;
    }

    .home-games-categories .first, .home-games-categories .second {
        width: 50%;
    }

        .home-games-categories .first > div > img, .home-games-categories .second > div > img {
            width: 100%;
        }

.home-game-category-label {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    font-size: 16px;
    font-family: Montserrat;
}

.first .home-game-category-label {
    left: 20px;
}

.second .home-game-category-label {
    right: 20px;
}

.home-games-categories > div > .category-active {
    opacity: 1;
    border-radius: 10px;
}

.home-games-categories > div > div {
    outline: 1px solid transparent;
}

.home-games-categories > div > .category-active-93connect {
    opacity: 1;
}

@keyframes myAnimation {
    0% {
        outline: 1px solid #90EE90;
    }

    50% {
        outline: 1px solid #0FFF50;
    }

    100% {
        outline: 1px solid #90EE90;
    }
}

#provider-section {
    overflow: hidden;
}

.provider-parent-container {
    width: 100%;
    min-width: 280px;
    overflow-x: auto;
}

.provider-category-container {
    display: flex;
    margin: 0px -3px;
}

    .provider-category-container div:last-child .provider-item .provider-logo-div {
        margin: 0 0 0 5px;
    }

.provider-item img {
    /* white-space: nowrap;
    background: linear-gradient(0deg, rgba(25, 35, 47, 0.2), rgba(25, 35, 47, 0.2)), linear-gradient(271.63deg, rgba(183, 30, 82, 0.3) 5.83%, rgba(247, 187, 151, 0.06) 100%);
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
    border-radius: 20px;
    padding: 5px 20px;
    font-size: 11px;
    color: #898F97;
    margin: 0px 3px;
    display: flex;
    align-items: center;*/

    border: 1px solid transparent;
    border-radius: 10px;
}

.provider-active img {
    /* color: #3369FF;
    background: linear-gradient(0deg, rgba(25, 35, 47, 0.2), rgba(25, 35, 47, 0.2)), linear-gradient(271.63deg, rgba(183, 30, 82, 0.3) 5.83%, rgba(247, 187, 151, 0.06) 100%); */
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
    border: 1px solid #3369FF;
}

.dot-wallet-available {
    width: 4px;
    height: 4px;
    background: linear-gradient(135deg, #95E581 0%, #82FFE8 100%);
    border-radius: 5px;
    margin-right: 10px;
}

.search-container {
    position: relative;
}

    .search-container input {
        background: radial-gradient(90.16% 143.01% at 15.32% 21.04%, rgba(51, 105, 255, 0.2) 0%, rgba(255, 255, 255, 0.0447917) 77.08%, rgba(255, 255, 255, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
        background-blend-mode: overlay, normal;
        backdrop-filter: blur(6.07811px);
        border: 1px solid #cbcbcb26;
        color: #3369FF;
        font-size: 12px;
        font-family: "poppins-medium";
        border-radius: 8px;
        padding: 0px 20px;
        width: 100%;
        height: 40px;
    }

        .search-container input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: #3369FF;
            opacity: 1; /* Firefox */
        }

        .search-container input:-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: #3369FF;
        }

        .search-container input::-ms-input-placeholder { /* Microsoft Edge */
            color: #3369FF;
        }

.search-btn {
    position: absolute;
    right: 0px;
    top: 0px;
    height: 100%;
    display: flex;
    align-items: center;
    width: 50px;
    justify-content: center;
}

.search-p {
    color: #94A3B8;
    font-size: 11px;
}

.stream-items {
    position: relative;
}

.stream-title {
    font-size: 15px;
    font-family: "poppins-medium";
}

.stream-content-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 10px;
    position: absolute;
    bottom: 15px;
    width: 100%;
}

.gamelist-title {
    font-size: 11px;
    text-align: center;
    max-width: 90px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.gamelist-slick {
    margin: 0px -10px;
}

.gamelist-items {
    padding: 0px 10px;
}

.pupular-pointer {
    /* width: 200px; */
    border-radius: 3px;
    width: 20px;
    height: 18px;
    position: relative;
    background: #FF334B;
    font-size: 11px;
    font-family: "poppins-black";
    display: inline-block;
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 1;
}

    .pupular-pointer:before {
        content: "";
        position: absolute;
        right: -8px;
        bottom: 0;
        width: 0;
        height: 0;
        border-left: 9px solid #FF334B;
        border-top: 9px solid transparent;
        border-bottom: 9px solid transparent;
    }

.gamelist-img-container {
    position: relative;
    border-radius: 8px;
    overflow: hidden;
}

.gamelist-img-inner-shadow {
    background: linear-gradient(180deg, rgba(17, 4, 42, 0) 0%, rgba(16, 24, 45, 0.72) 68.75%, #10182D 100%);
    height: 56px;
    width: 100%;
    bottom: 0px;
    position: absolute;
}

.gamelist-provider {
    font-size: 8px;
    font-family: "poppins-medium";
    bottom: 7px;
    position: absolute;
    width: 100%;
    text-align: center;
}

#custom-modal {
    align-items: flex-end;
    height: 100vh;
    margin: 0 !important;
}

    #custom-modal .modal-content {
        background: transparent;
        box-shadow: unset;
        max-height: 90%;
    }

        #custom-modal .modal-content::-webkit-scrollbar {
            display: none;
        }

    #custom-modal .modal-body {
        padding: 40px 20px 40px;
        max-width: 500px;
        margin: auto;
        background: radial-gradient(90.16% 143.01% at 15.32% 21.04%, rgba(15, 36, 94, 0.15) 0%, rgba(255, 51, 75, 0.012) 77.08%, rgba(6, 22, 65, 0.15) 100%);
        -webkit-backdrop-filter: blur(35px);
        backdrop-filter: blur(35px);
        border-radius: 65px 65px 0px 0px;
        border: 0px;
        width: 100%;
    }

#custom-modal2 {
    align-items: flex-end;
    height: 100vh;
    margin: 0 !important;
}

    #custom-modal2 .modal-content {
        background: transparent;
        box-shadow: unset;
        max-height: 90%;
        height: 74%;
    }

        #custom-modal2 .modal-content::-webkit-scrollbar {
            display: none;
        }

    #custom-modal2 .modal-body {
        padding: 40px 20px 40px;
        max-width: 500px;
        margin: auto;
        background: radial-gradient(90.16% 143.01% at 15.32% 21.04%, rgba(15, 36, 94, 0.15) 0%, rgba(255, 51, 75, 0.012) 77.08%, rgba(6, 22, 65, 0.15) 100%);
        -webkit-backdrop-filter: blur(35px);
        backdrop-filter: blur(35px);
        border-radius: 65px 65px 0px 0px;
        border: 0px;
        width: 100%;
    }


.custom-modal-title {
    color: #EFEFEF;
    font-size: 35px;
    font-family: "poppins-semibold";
}

.flex-box-centered {
    display: flex;
    align-items: center;
    justify-content: center;
}

.pop-up-under-title-text {
    color: #A4A4A4;
    font-size: 14px;
}

.modal-backdrop {
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(2.5px);
    opacity: 1 !important;
}

.input-container label {
    display: block;
    color: white;
    font-size: 14px;
    font-weight: 500;
}

.input-with-icon-container {
    position: relative;
}

.form-group input {
    background-color: #132144 !important;
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
    border-radius: 8px !important;
    border: 0px;
    width: 100%;
    font-size: 14px;
    color: #A4A4A4 !important;
    height: 50px;
    padding: 0px 20px 0px;
    outline: unset;
}

    .form-group input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #A4A4A4;
        font-weight: 400;
        opacity: 1; /* Firefox */
    }

    .form-group input:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: #A4A4A4 !important;
        font-weight: 400;
    }

    .form-group input::-ms-input-placeholder { /* Microsoft Edge */
        color: #A4A4A4 !important;
        font-weight: 400;
    }

.form-group.m-b-15 {
    margin-bottom: 24px !important;
}

.custom-input {
    background: linear-gradient(0deg, rgba(25, 35, 47, 0.2), rgba(25, 35, 47, 0.2)), linear-gradient(271.33deg, rgba(78, 75, 200, 0.3) 0%, rgba(254, 94, 171, 0.03) 100%);
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    border: 0px;
    width: 100%;
    font-size: 14px;
    color: #A4A4A4;
    height: 50px;
    padding: 0px 20px 0px 45px;
}

.input-hidden {
    padding-right: 45px;
}

.custom-input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #A4A4A4;
    opacity: 1; /* Firefox */
}

.custom-input:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #A4A4A4;
}

.custom-input::-ms-input-placeholder { /* Microsoft Edge */
    color: #A4A4A4;
}

.form-group .custom-input {
    background: linear-gradient(0deg, rgba(25, 35, 47, 0.2), rgba(25, 35, 47, 0.2)), linear-gradient(271.33deg, rgba(78, 75, 200, 0.3) 0%, rgba(254, 94, 171, 0.03) 100%);
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    border: 0px;
    width: 100%;
    font-size: 14px;
    color: #A4A4A4;
    height: 50px;
    padding: 0px 20px 0px 45px;
}

.form-group .input-hidden {
    padding-right: 45px;
}

.form-group .custom-input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #A4A4A4;
    opacity: 1; /* Firefox */
}

.form-group .custom-input:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #A4A4A4;
}

.form-group .custom-input::-ms-input-placeholder { /* Microsoft Edge */
    color: #A4A4A4;
}

.input-icon {
    position: absolute;
    top: 17px;
    left: 15px;
}

a {
    cursor: pointer;
}

.custom-btn {
    background: #3369FF;
    box-shadow: 0px 5px 30px rgba(51, 105, 255, 0.7);
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    font-size: 14px;
    font-family: Montserrat;
}

.input-hidden-icon {
    position: absolute;
    right: 13px;
    top: 17px;
    /*bottom: 34px;*/
    /*left: 34px;*/
    /*    height: 100%;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;*/
}

.custom-checkbox input {
    display: none;
}

.custom-checkbox span {
    border: 2px solid transparent;
    border-radius: 1px;
    background: #A4A4A499;
    width: 18px;
    height: 18px;
    min-width: 18px;
    min-height: 18px;
    display: inline-block;
    border-radius: 5px;
}

.custom-checkbox input:checked ~ span {
    background-color: #48b32dcc;
    background-image: url("../../assets/img/v3/check.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.custom-checkbox {
    font-size: 12px;
    margin-bottom: 6px;
    padding-left: 0;
    color: white;
    align-items: center;
    grid-column-gap: 15px;
    -webkit-column-gap: 15px;
    column-gap: 15px;
    display: flex;
    font-weight: 400;
}

.forgot-password {
    font-size: 12px;
    font-weight: 500;
    color: #A4A4A4 !important;
    text-align: center;
    display: block;
}

.modal-height-100 .modal-content {
    height: 100%;
    overflow-y: auto;
}

.modal-custom-header {
    display: flex;
    column-gap: 20px;
    align-items: center;
}

    .modal-custom-header h2 {
        font-size: 24px;
        font-family: "poppins-semibold";
    }

.flex-spacebetween-row {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.custom-pin-container {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 15px;
}

    .custom-pin-container input {
        font-size: 24px;
        font-family: "poppins-medium";
        background: linear-gradient(0deg, rgba(119, 135, 154, 0.2), rgba(119, 135, 154, 0.2)), linear-gradient(271.33deg, rgba(78, 75, 200, 0.5) 0%, rgba(254, 94, 171, 0.05) 100%);
        box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
        border-radius: 8px;
        border: 0px;
        width: 50px;
        height: 50px;
        color: #A4A4A4;
        outline: unset;
        text-align: center;
    }

        .custom-pin-container input:focus-visible {
            border: 2px solid #2E57C9;
        }

#success-modal {
    padding: 0px 20px;
}

    #success-modal .modal-content {
        border-radius: 30px;
        overflow: hidden;
        background-color: #2c2c2c;
        color: #ffffff;
    }

    #success-modal .modal-body {
        background: linear-gradient(0deg, rgba(36, 80, 202, 0.5) 0%, rgb(248 230 255 / 10%) 100%);
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15));
    }

.modal-success-title {
    font-size: 18px;
    font-family: "poppins-semibold";
    color: #FFFFFF;
}

.modal-success-details {
    font-size: 10px;
    font-weight: 500;
    color: #A4A4A4;
}

.modal-success-container {
    text-align: center;
}

    .modal-success-container img {
        margin: auto;
    }

.home-wallet-container {
    background: linear-gradient(0deg, rgba(25, 35, 47, 0.2), rgba(25, 35, 47, 0.2)), linear-gradient(271.33deg, rgba(78, 75, 200, 0.3) 0%, rgba(254, 94, 171, 0.03) 100%);
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
    padding: 15px 15px;
    border-radius: 8px;
    margin-top: 12px !important;
}

    .home-wallet-container .home-wallet-balance-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

        .home-wallet-container .home-wallet-balance-box .first {
            display: flex;
            align-items: center;
            column-gap: 15px;
        }

            .home-wallet-container .home-wallet-balance-box .first h6 {
                font-size: 10px;
                font-weight: 500;
            }

            .home-wallet-container .home-wallet-balance-box .first p {
                font-size: 16px;
                font-weight: 500;
                line-height: 20px;
            }

        .home-wallet-container .home-wallet-balance-box .second {
            display: flex;
            background: #3369FF;
            border-radius: 20px;
            padding: 8px 15px;
            font-size: 10px;
            align-items: center;
            line-height: 1;
            column-gap: 8px;
        }

.home-wallet-reload-text {
    margin-top: 2px;
}

.home-wallet-icon {
    background: linear-gradient(316.1deg, rgba(51, 105, 255, 0.4) -6.32%, rgba(27, 42, 84, 0) 92.73%);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15));
    border-radius: 8px;
    margin-bottom: 5px;
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
}

    .home-wallet-icon img {
        height: 20px;
    }

.home-wallet-balance-amount {
    font-size: 15px;
    margin-bottom: 0px;
    padding: 0px 8px;
}

.home-points-list {
    display: flex;
    align-items: center;
    column-gap: 10px;
}

    .home-points-list > div {
        width: 50%;
        min-height: 70px;
    }

.home-points-items {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    background: #1B2A54;
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
    padding: 10px 10px;
    border-radius: 8px;
    column-gap: 10px;
    position: relative;
}

    .home-points-items h6 {
        font-size: 12px;
        font-weight: 500;
    }

    .home-points-items p {
        font-size: 12px;
        font-weight: 500;
    }

/* .home-points-icon{
    background: linear-gradient(221.94deg, rgba(77, 49, 233, 0.4) 0%, rgba(216, 104, 255, 0) 99.53%);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15));
    border-radius: 8px;
    padding: 3px 6px;
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
} */
.home-button-list {
    display: grid;
    align-items: center;
    text-align: center;
    grid-template-columns: 18% 18% 18% 18% 18%;
    justify-content: space-between;
    /* grid-gap: 10px; */
}

    .home-button-list > div {
        /* background: #141A50; */
        background: linear-gradient(0deg, rgba(25, 35, 47, 0.2), rgba(25, 35, 47, 0.2)), linear-gradient(271.33deg, rgba(78, 75, 200, 0.3) 0%, rgba(254, 94, 171, 0.03) 100%);
        width: 100%;
        height: 60px;
        text-align: center;
        box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.1);
        padding: 10px 5px;
        border-radius: 8px;
    }

.home-button-items .icon > div:first-child {
    display: flex;
    align-items: self-start;
    justify-content: flex-end;
    height: 30px;
    width: 100%;
    margin-top: -5px;
    margin-bottom: 5px;
}

.home-button-items .icon {
    display: flex;
    flex-direction: column;
    align-items: center;
}

    .home-button-items .icon .text {
        text-align: center;
        color: white;
        font-size: 9px;
        font-weight: 400;
    }
/* //////end john css//// */

.keyboard.keyboard-numpad.keyboard-numpad-v3 {
    background-color: rgba(22, 26, 47, 0.8);
    border-radius: 50px 50px 0 0;
    backdrop-filter: blur(35px);
    padding: 1rem 0 0 0;
    margin-top: 0;
}

    .keyboard.keyboard-numpad.keyboard-numpad-v3 .hg-row {
        grid-gap: unset;
        max-width: unset;
        width: 100%;
    }

    .keyboard.keyboard-numpad.keyboard-numpad-v3 .hg-button {
        background: transparent;
        box-shadow: unset;
        border-radius: unset;
        width: 100%;
        height: 5rem;
        color: white;
        font-size: 18px;
        user-select: none;
    }

.custom-pin-container.tac-container {
    display: unset !important;
    width: 100%;
}

    .custom-pin-container.tac-container .pincode-input-container {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        width: 100%;
    }

.custom-pin-container .pincode-input-container .pincode-input-text {
    overflow: hidden;
    font-size: 24px;
    font-family: poppins-medium;
    background: linear-gradient(0deg, rgba(119, 135, 154, 0.2), rgba(119, 135, 154, 0.2)), linear-gradient(271.33deg, rgba(78, 75, 200, 0.5) 0%, rgba(254, 94, 171, 0.05) 100%) !important;
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
    border-radius: 8px !important;
    border: 0px !important;
    width: 50px !important;
    height: 50px !important;
    color: #A4A4A4;
    outline: unset;
    text-align: center;
    border-bottom: 2px solid #fff !important;
}

.phone-dropdown-container {
    background-color: #31376e;
    /* background: linear-gradient(0deg, rgba(25, 35, 47, 0.2), rgba(25, 35, 47, 0.2)), linear-gradient(271.33deg, rgba(78, 75, 200, 0.3) 0%, rgba(254, 94, 171, 0.03) 100%); */
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    color: #EBEBF599;
    font-size: 14px;
    position: absolute;
}

.phone-dropdown-items {
    display: flex;
    align-items: center;
    column-gap: 10px;
    padding: 3px 20px;
}

.custom-phone-field {
    display: flex;
    column-gap: 10px;
    align-items: center;
}

.custom-phone-container {
    display: flex;
    align-items: center;
    column-gap: 10px;
    background: linear-gradient(0deg, rgba(25, 35, 47, 0.2), rgba(25, 35, 47, 0.2)), linear-gradient(271.33deg, rgba(78, 75, 200, 0.3) 0%, rgba(254, 94, 171, 0.03) 100%);
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    border: 0px;
    width: 100%;
    font-size: 14px;
    color: #A4A4A4;
    height: 50px;
    padding: 0px 20px 0px 9px;
}

.custom-phone-input {
    font-size: 14px;
    color: #A4A4A4;
    width: 100%;
    background: transparent !important;
    border: 0px !important;
    outline: unset !important;
}

.custom-phone-container .second {
    width: 100%;
}

.custom-phone-container-1 {
    display: flex;
    align-items: center;
    column-gap: 10px;
    border: 0px;
    background-color: #132144 !important;
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
    border-radius: 8px !important;
    width: 100%;
    font-size: 14px;
    color: #A4A4A4 !important;
    height: 50px;
    padding: 0px 20px 0px;
    outline: unset;
}

    .custom-phone-container-1 .second-1 {
        width: 100%;
        box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
    }

.custom-phone-input-1 {
    font-size: 14px;
    color: #A4A4A4;
    width: 100%;
    background: transparent !important;
    outline: unset !important;
    border: 0px !important;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.3) !important
}
/* //////end john css//// */

#custom-modal .modal-body::-webkit-scrollbar {
    display: none;
}

.load-table-wrapper {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-template-rows: 2fr;
    overflow-y: scroll;
    background: transparent;
    justify-items: center;
    -webkit-user-select: none;
    user-select: none;
}

    .load-table-wrapper .hot-games-tile {
        margin: 15px 0 0 0;
    }

    .load-table-wrapper .hot-games-text {
        color: white;
    }

.games-icon-grid {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-template-rows: 2fr;
    justify-items: center;
}

    .games-icon-grid .hot-games-text {
        color: white;
    }

.games-icon-wrapper {
    margin: 5px 10px;
    max-height: 200px;
}

.hot-games-link {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

/*.tnc-popup-wrapper {
    display: flex;
    overflow-x: scroll;
}*/

.tnc-popup {
    display: grid;
    overflow-y: scroll;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 200%;
    background-color: #0D1939;
}

.tnc-popup-header-v3 {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    color: #EFEFEF;
    margin-bottom: 15px;
}

.tnc-popup-label {
    color: #3369FF;
}

.terms-of-use .tnc-general .tnc-memberAcc .tnc-depositNwithdrawal .tnc-bettingTransactions .tnc-collusion .tnc-interruptions .tnc-administrations .tnc-customerService .tnc-list {
    color: #A4A4A4;
}

.tnc-btn-container {
    display: flex;
    column-gap: 15px;
    align-items: center;
    width: 100%;
}

.tnc-button {
    /*paddingRight: 8px;*/
    width: 100%;
    display: block;
}

.wallet-modal-title {
    color: #EFEFEF;
    font-size: 30px;
    font-family: "poppins-semibold";
}

.game-wallet-container {
    background: linear-gradient(0deg, rgba(25, 35, 47, 0.2), rgba(25, 35, 47, 0.2)), linear-gradient(271.33deg, rgba(78, 75, 200, 0.3) 0%, rgba(254, 94, 171, 0.03) 100%);
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    padding: 10px 10px 8px;
    display: flex;
}

    .game-wallet-container h6 {
        font-size: 10px;
        font-weight: 500;
    }

    .game-wallet-container p {
        font-size: 14px;
        font-weight: 500;
    }

    .game-wallet-container > div {
        width: 100%;
        display: block;
        text-align: center;
    }

    .game-wallet-container .first {
        border-right: 1px solid #FFFFFF1A;
    }

.game-wallet-amount-label {
    font-size: 14px;
    color: #A4A4A4;
    font-family: "poppins-regular";
    font-weight: 500;
}

.game-wallet-amount-container {
    display: flex;
    align-items: center;
    column-gap: 10px;
    background: linear-gradient(0deg, rgba(25, 35, 47, 0.2), rgba(25, 35, 47, 0.2)), linear-gradient(271.33deg, rgba(78, 75, 200, 0.3) 0%, rgba(254, 94, 171, 0.03) 100%);
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    padding: 0px 20px;
}

    .game-wallet-amount-container > .first {
        width: 100%;
    }

.game-wallet-input {
    border-radius: 2px;
    border: 0px;
    width: 100%;
    font-size: 14px;
    color: #A4A4A4;
    background: transparent;
    height: 50px;
    outline: unset;
}

    .game-wallet-input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #A4A4A4;
        opacity: 1; /* Firefox */
    }

    .game-wallet-input:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: #A4A4A4;
    }

    .game-wallet-input::-ms-input-placeholder { /* Microsoft Edge */
        color: #A4A4A4;
    }

.game-wallet-btn {
    display: flex;
    background: #3369FF;
    color: white;
    border: none;
    border-radius: 20px;
    padding: 5px 15px;
    font-size: 12px;
    align-items: center;
    line-height: 1;
    grid-column-gap: 8px;
    column-gap: 8px;
    font-weight: 400;
    font-family: "poppins-regular";
    white-space: nowrap;
}

.game-wallet-new-balance-container h3 {
    font-family: "poppins-regular";
    font-weight: unset;
    font-size: 16px;
}

.game-wallet-new-balance-container p {
    font-family: "poppins-regular";
    font-weight: unset;
    font-size: 12px;
    color: #A4A4A4;
}

.game-wallet-account-container {
    display: flex;
    align-items: center;
    column-gap: 15px;
    justify-content: center;
}

    .game-wallet-account-container .first {
        width: 62px;
        height: 1px;
        background: linear-gradient(270deg, rgba(51, 105, 255, 0.75) 0%, rgba(217, 217, 217, 0) 94.39%);
    }

    .game-wallet-account-container .second {
        color: #3369FF;
        font-size: 16px;
        font-family: "poppins-semibold";
    }

    .game-wallet-account-container .third {
        width: 62px;
        height: 1px;
        background: linear-gradient(89.99deg, rgba(51, 105, 255, 0.75) 0%, rgba(217, 217, 217, 0) 105.61%);
    }

.game-account-details-box {
    display: flex;
    font-weight: unset;
    font-size: 12px;
    color: #A4A4A4;
    align-items: center;
    line-height: 1;
    justify-content: space-between;
}

.game-account-icon-box {
    display: flex;
    align-items: center;
    column-gap: 12px;
}

#nav-modal {
    max-width: 215px;
    margin: auto;
    margin-right: 0px;
}

    #nav-modal .modal-content {
        background: radial-gradient(90.16% 143.01% at 15.32% 21.04%, rgba(15, 36, 94, 0.15) 0%, rgba(255, 51, 75, 0.012) 77.08%, rgba(6, 22, 65, 0.15) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
        backdrop-filter: blur(35px);
        -webkit-backdrop-filter: blur(35px);
        border-radius: 45px 0px 0px 45px;
        border: 0px;
        box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
        padding: 50px 30px;
        min-height: 100vh;
    }

    #nav-modal .modal-body {
        padding: 0px;
    }

.nav-title {
    font-weight: 500;
    font-size: 22px;
    line-height: 1;
}

    .nav-title .second {
        display: flex;
        align-items: flex-end;
        column-gap: 5px;
    }

.custom-hr {
    background-color: rgba(255, 255, 255, 0.1);
    width: 100%;
    height: 1px;
}

.nav-items {
    display: flex;
    align-items: flex-start;
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 30px;
    color: white;
}

    .nav-items > .first {
        display: flex;
        min-width: 40px;
        max-width: 40px;
        justify-content: flex-start;
    }

.navList-container > div:last-child {
    margin-bottom: 0px;
}

.social-list li {
    display: inline-block;
    margin-right: 10px;
}

    .social-list li:last-child {
        margin-right: 0px;
    }

.social-icon-title {
    font-size: 11px;
}

#nav-modal .custom-select-field {
    max-width: 100%;
    width: 100%;
    min-width: unset;
    font-size: 12px;
    padding: 8px 10px;
}

.provider-logo-div {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 50px;
    width: 130px;
    margin: 0 5px;
}

.new-games-spinner {
    margin: 20px;
}

.sweet-alert-container span {
    color: white;
    font-size: 20px;
}

.app-v3 {
    min-height: 100vh;
    background-color: #0D1A3B;
    padding: 0 20px;
}

/* start - related to home page game wallet transfer numpad */
.modal-content.modal-brand.modal-bottom.modal-numpad.popup-transfer-numpad {
    background: rgba(22, 26, 47) !important;
}

.popup-transfer-numpad strong.text-brand.display-value {
    color: white;
}

.popup-transfer-numpad .text-brand.text-center {
    color: white;
}

.popup-transfer-numpad span {
    color: white;
}
/* end - related to home page game wallet transfer numpad */

.text-for-copy {
    background: transparent;
    border: none;
    color: #A4A4A4;
}

    .text-for-copy:focus-visible {
        outline: none;
    }

.text-color-green {
    color: green;
}

.alert-text {
    position: absolute;
    margin-top: 25px;
}

.date-selection-wrapper .selection-item {
    color: black;
    font-weight: bold;
}

.filter-grid-wrapper .form-control[readonly] {
    background: white;
}

.css-2b097c-container .css-26l3qy-menu {
    color: black;
}

.rdt thead {
    color: black;
}

.home-points-items:hover .tooltip-text {
    visibility: visible !important;
    width: 150px;
    top: 25px;
}

.home-points-items:not(:nth-child(3)):hover .tooltip-text {
    left: 25px;
}

.home-points-items:nth-child(3):hover .tooltip-text {
    right: 25px;
}

.home-username-qr-box .first {
    font-weight: bold;
    font-size: 13px;
    line-height: 1;
    display: flex;
    font-family: 'Montserrat';
    align-items: center;
    column-gap: 15px;
}

    .home-username-qr-box .first > div:last-child {
        margin-bottom: 2px;
    }

.home-username-qr-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

    .home-username-qr-box .second {
        /* background: linear-gradient(316.1deg, rgba(51, 105, 255, 0.4) -6.32%, rgba(27, 42, 84, 0) 92.73%); */
        background: linear-gradient(221.94deg, rgba(77, 49, 233, 0.4) 0%, rgba(27, 42, 84, 0.4) 99.53%);
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15));
        border-radius: 8px;
        padding: 3px 7px;
        box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
    }

.header-lang-nav-container {
    display: flex;
    align-items: center;
    column-gap: 15px;
}

    .header-lang-nav-container .custom-select-field {
        min-width: unset;
        padding: 4px;
        border-radius: 100px;
        margin-bottom: 0px;
    }

    .header-lang-nav-container .country-dropdown-container {
        width: auto;
        z-index: 1;
        right: 10px;
        top: 50px;
        margin-top: 5px;
    }

    .header-lang-nav-container .custom-select-field {
        font-size: 10px;
        font-weight: 400;
    }

    .header-lang-nav-container .country-dropdown-container {
        font-size: 12px;
        font-weight: 400;
    }

    .header-lang-nav-container .country-dropdown-items .first {
        display: flex;
        align-items: center;
        grid-column-gap: 10px;
    }

    .header-lang-nav-container .country-dropdown-items {
        display: block;
    }

    .header-lang-nav-container .country-child-container {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

        .header-lang-nav-container .country-child-container > div {
            padding: 0px 8px;
            border-right: 1px solid #EBEBF566;
            font-size: 10px;
            margin: 5px 0px;
            font-weight: 400;
        }

            .header-lang-nav-container .country-child-container > div:last-child {
                border: unset;
            }

            .header-lang-nav-container .country-child-container > div:first-child {
                padding-left: 0px;
            }

    .header-lang-nav-container .country-dropdown-container > .active {
        background: rgba(217, 217, 217, 0.06);
    }

    .header-lang-nav-container .country-dropdown-container {
        overflow: hidden;
    }

    .header-lang-nav-container .country-child-container .active {
        color: #3369FF;
    }

.modal-content.modal-brand.modal-bottom.modal-reload-success.new-success-modal {
    background: rgba(22, 26, 47);
}

.new-success-modal.modal-reload-success .reload-title {
    color: white !important;
}

.new-success-modal .text-brand.text-bo {
    color: white;
}

.new-success-modal .table.table-reload-detail th {
    color: white;
}

.country-row-container {
    padding: 10px;
}

.country-child-lang {
    white-space: nowrap;
}

.see-more-text {
    position: static;
    margin: 15px 0;
    color: #0078ff;
    text-decoration: underline;
}

.page-custom-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
    margin-bottom: 30px;
}

.page-custom-header > .first {
    display: flex;
    align-items: center;
    column-gap: 30px;
    line-height: 1;
    font-size: 16px;
}

.page-custom-header.email-birthdate-header {
    display: flex;
    justify-content: center;
}

.page-custom-header.email-birthdate-header > .first {
    flex-direction: column;
    justify-content: center;
    width: 275px;
}

.page-custom-header.email-birthdate-header > .first > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.page-custom-header.email-birthdate-header > .first > div > span:first-child {
    font-size: 18px;
    text-align: center;
}

.page-custom-header.email-birthdate-header > .first > div > span:last-child {
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    color: #9B9C9E;
}

.bonus-slick {
    margin: 20px -11px 0px;
    height: 107px;
    display: flex;
    justify-content: start;
    align-items: start;
}

.bonus-slick .slick-list {
    height: 100%;
    width: 100%;
    overflow-x: auto;
}

.bonus-slick .slick-track {
    display: grid;
    grid-auto-flow: column;
    column-gap: 10px;
    height: 100%;
    width: 100% !important;
}

.bonus-slick .slick-track > div {
    width: 160px !important;
    height: 94px !important;
    height: 100%;
}

.bonus-items {
   margin: 8px;
}
    /* .bonus-items .bonus-img{
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3), inset 0px 1.5px 0.5px rgba(255, 255, 255, 0.1);
} */

.bonus-items .bonus-img {
    border-radius: 8px;
    /* border: 2px solid transparent; */
    position: relative;
    /* overflow: hidden; */
}

.bonus-items .bonus-img img {
    -webkit-filter: grayscale(40%); /* Safari 6.0 - 9.0 */
    filter: grayscale(40%);
    border-radius: 8px;
}

.bonus-items .bonus-img:after {
    content: ' ';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #0000001e;
    top: 0;
    left: 0;
}

.bonus-items .img-100.active {
    -webkit-filter: grayscale(0%); /* Safari 6.0 - 9.0 */
    filter: grayscale(0%);
}

.bonus-items .info-icon {
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: 99;
}
    /*.bonus-items:focus .bonus-img{
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3), inset 0px 1.5px 0.5px rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    border: 2px solid transparent;
    background: linear-gradient(283.88deg, #FFD928 20.04%, rgba(255, 255, 255, 0) 45.99%),
    linear-gradient(109.94deg, #FFD928 8.46%, rgba(255, 255, 255, 0) 60.26%);
}*/

    .bonus-items .bonus-img.active {
        box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3), inset 0px 1.5px 0.5px rgba(255, 255, 255, 0.1);
        border-radius: 8px;
        border: 3px solid #3369FF;
        position: relative;
      /*  background: linear-gradient(283.88deg, #FFD928 20.04%, rgba(255, 255, 255, 0) 45.99%), linear-gradient(109.94deg, #FFD928 8.46%, rgba(255, 255, 255, 0) 60.26%);*/
        /**/
    }

    .bonus-items:hover .bonus-img img {
        -webkit-filter: grayscale(0%); /* Safari 6.0 - 9.0 */
        filter: grayscale(0%);
    }

    .bonus-items:hover .bonus-img:after {
        display: none;
    }

.payment-custom-radio {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0px;
    cursor: pointer;
}

    .payment-custom-radio input {
        display: none;
    }

.payment-custom-radio-label {
    line-height: 1;
    color: #898F97;
    font-size: 14px;
    display: flex;
    align-items: center;
    column-gap: 10px;
}

    .payment-custom-radio-label > div {
        display: flex;
        align-items: center;
    }

.payment-custom-radio .checkmark-border {
    border: 2px solid #898F97;
    border-radius: 100px;
    display: inline-block;
    margin-right: 10px;
    line-height: 0;
}

.payment-custom-radio .checkmark-dot {
    border-radius: 100px;
    background: #898F97;
    width: 7px;
    height: 7px;
    display: inline-block;
    margin: 2px;
}

.payment-custom-radio input:checked ~ .checkmark-border {
    border: 2px solid #3369FF;
}

    .payment-custom-radio input:checked ~ .checkmark-border .checkmark-dot {
        background: #3369FF;
    }

.payment-custom-radio input:checked ~ .checkmark-title {
    color: #3369FF;
}

.payment-custom-radio input:checked ~ div .checkmark-title {
    color: #3369FF;
}

.payment-custom-radio input:checked ~ div .checkmark-details {
    color: #3369FF;
}

.payment-list-item {
    background: linear-gradient(0deg, rgba(51, 105, 255, 0.1), rgba(51, 105, 255, 0.1)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%);
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 15px;
}

.payment-provider-box {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    margin-top: 10px;
    padding-top: 10px;
}

.payment-provider-box {
    display: grid;
    align-items: center;
    text-align: center;
    grid-template-columns: 25% 25% 25% 25%;
    display: none;
    /* grid-gap: 10px; */
}

    .payment-provider-box > div {
        height: 46px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .payment-provider-box > .active {
        background: rgba(51, 105, 255, 0.2);
        border-radius: 8px;
        border: 1px solid #3369FF;
    }

.d-grid {
    display: grid !important;
}

.custom-input-container select {
    /* background-image: ; */
    background: url("../../assets/img/v3/arrow-down.png");
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    border: 0px;
    width: 100%;
    font-size: 14px;
    color: #A4A4A4;
    height: 50px;
    padding: 0px 20px 0px;
    outline: unset;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: #132144;
    background-repeat: no-repeat;
    background-position: right 20px center;
}

.custom-input-container input {
    background-color: #132144;
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
    border-radius: 8px !important;
    border: 0px;
    width: 100%;
    font-size: 14px;
    color: #A4A4A4;
    height: 50px;
    padding: 0px 20px 0px;
    outline: unset;
}

    .custom-input-container input:focus {
        background-color: #132144;
        box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
        border-radius: 8px !important;
        border: 1px solid #5787CD;
        width: 100%;
        font-size: 14px;
        color: #A4A4A4;
        height: 50px;
        padding: 0px 20px 0px;
        outline: unset;
    }

#custom-modal.bonus-modal .modal-body {
    padding: 0px;
}

.deposit-bonus-modal-content {
    padding: 20px 20px 30px;
}

.deposit-bonus-modal-title-box {
    display: flex;
    align-items: center;
    column-gap: 15px;
}

    .deposit-bonus-modal-title-box .first {
        background: linear-gradient(316.1deg, rgba(51, 105, 255, 0.4) -6.32%, rgba(27, 42, 84, 0) 92.73%);
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15));
        border-radius: 8px;
        padding: 8px 11px;
        box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
    }

.deposit-bonus-modal-details {
    font-size: 16px;
    color: #A4A4A4;
}

.upload-file-box {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
    height: 115px;
    column-gap: 10px;
    background: transparent;
    border: 1px dashed #3369FF;
    border-radius: 8px;
    color: #898F97;
}

.deposit-normal-qr-box {
    display: flex;
    column-gap: 15px;
    justify-content: space-between;
}

    .deposit-normal-qr-box .first {
        color: #A4A4A4;
    }

        .deposit-normal-qr-box .first a {
            color: #3369FF;
            text-decoration: underline;
        }

    .deposit-normal-qr-box .second {
        background: linear-gradient(0deg, rgba(25, 35, 47, 0.2), rgba(25, 35, 47, 0.2)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%);
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15));
        border-radius: 8px;
        padding: 8px 11px;
        box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
    }

.withdraw-cash-balance-container {
    background: linear-gradient(180deg, rgba(51, 105, 255, 0.24) -6.16%, rgba(27, 42, 84, 0.24) 110.96%);
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3), inset 0px 1.5px 0.5px rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    padding: 15px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #898F97;
    font-size: 14px;
}

    .withdraw-cash-balance-container .first {
        display: flex;
        align-items: center;
        column-gap: 10px;
    }

        .withdraw-cash-balance-container .first .first-child {
            background: linear-gradient(316.1deg, rgba(51, 105, 255, 0.4) -6.32%, rgba(27, 42, 84, 0) 92.73%);
            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15));
            border-radius: 8px;
            padding: 3px 10px;
            box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
        }

.transfer-header-tab-container {
    display: flex;
    align-items: center;
    background: linear-gradient(180deg, rgba(51, 105, 255, 0.24) -6.16%, rgba(27, 42, 84, 0.24) 110.96%);
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3), inset 0px 1.5px 0.5px rgba(255, 255, 255, 0.1);
    border-radius: 50px;
    font-size: 12px;
    padding: 5px;
}

    .transfer-header-tab-container > div {
        width: 50%;
        text-align: center;
        border-radius: 30px;
        height: 42px;
        align-items: center;
        justify-content: center;
        display: flex;
    }

    .transfer-header-tab-container > .active {
        background: linear-gradient(0deg, rgba(51, 105, 255, 0.1), rgba(51, 105, 255, 0.1)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%);
        box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
    }

.container-btn-below {
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: space-between;
}

#provider-lobby .header {
    background: transparent;
    color: white;
}

.home-member-details-wrapper {
    display: flex;
    justify-content: space-between;
}

.home-username-wallet-box {
    display: flex;
    justify-content: space-between;
}

.home-username-box {
    display: flex;
    align-items: center;
}

.home-wallet-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.home-wallet-balance {
    display: flex;
    align-items: center;
    position: relative;
}

.home-wallet-balance-update {
    display: flex;
    align-items: center;
    position: relative;
    margin: 0px 10px;
}

.home-wallet-reload {
    display: flex;
    background: #3369ff;
    border-radius: 20px;
    padding: 7px 20px;
    font-size: 12px;
    align-items: center;
    line-height: 1;
    grid-column-gap: 5px;
}

.qr-box-wrapper {
  background: linear-gradient(316.1deg,rgba(51,105,255,.4) -6.32%,rgba(27,42,84,0) 92.73%);
  -webkit-filter: drop-shadow(0px 4px 4px rgba(0,0,0,.15));
  filter: drop-shadow(0px 4px 4px rgba(0,0,0,.15));
  border-radius: 5px;
  padding: 5px 8px;
  box-shadow: 0 4px 7px #0000004d;
  width: 60px;
  height: 60px;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
  position: relative;
  margin-left: 5px;
  cursor: pointer;
}

.qr-box-wrapper img {
    width: 100%;
    height: 100%;
}

.react-simple-keyboard span {
    color: black;
}

.keyboard.keyboard-numpad.keyboard-numpad-v3.react-simple-keyboard .hg-row span {
    color: white;
}

.verify-withdraw-pin .pin-number {
    background-color: rgba(22, 26, 47, 0.8);
    border-radius: 50px 50px 0 0;
    backdrop-filter: blur(35px);
}

.verify-withdraw-pin button {
    color: white !important;
}

.verify-withdraw-pin .text-brand {
    color: white;
}

.verify-withdraw-pin input.pincode-input-text {
    border-bottom: 2px solid #fff !important;
    color: white;
}

.custom-icon-box {
    background: linear-gradient(316.1deg, rgba(51, 105, 255, 0.4) -6.32%, rgba(27, 42, 84, 0) 92.73%);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15));
    border-radius: 8px;
    height: 26px;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3)
}

.main-wallet-methods-container {
    display: flex;
    align-items: center;
    column-gap: 20px;
    font-size: 14px;
    line-height: 1;
}

    .main-wallet-methods-container > div {
        width: 50%;
    }

    .main-wallet-methods-container > .first {
        background: linear-gradient(180deg, rgba(51, 105, 255, 0.24) -6.16%, rgba(27, 42, 84, 0.24) 110.96%);
        box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3), inset 0px 1.5px 0.5px rgba(255, 255, 255, 0.1);
        border-radius: 8px;
        padding: 15px 15px;
        height: 95px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .main-wallet-methods-container > .second {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 95px;
    }

        .main-wallet-methods-container > .second > div {
            background: linear-gradient(180deg, rgba(51, 105, 255, 0.24) -6.16%, rgba(27, 42, 84, 0.24) 110.96%);
            box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3), inset 0px 1.5px 0.5px rgba(255, 255, 255, 0.1);
            border-radius: 8px;
            padding: 8px 15px;
            display: flex;
            align-items: center;
            column-gap: 10px;
        }

.main-wallet-list-item {
    background: linear-gradient(0deg, rgba(25, 35, 47, 0.2), rgba(25, 35, 47, 0.2)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%);
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    padding: 19px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    line-height: 1;
    color: #898F97;
/*    margin-top: 1.5em;*/
}

.custom-date-filter-container {
    background: radial-gradient(90.16% 143.01% at 15.32% 21.04%, rgba(51, 105, 255, 0.2) 0%, rgba(255, 255, 255, 0.0447917) 77.08%, rgba(255, 255, 255, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
    background-blend-mode: overlay, normal;
    backdrop-filter: blur(6.07811px);
    border: 1px solid #ffffff2f;
    border-radius: 8px;
    padding: 0px 20px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 1;
}

    .custom-date-filter-container .first {
        display: flex;
        align-items: center;
    }

    .custom-date-filter-container .second img {
        margin-bottom: 2px;
    }

    .custom-date-filter-container input {
        padding: 0px;
        border: 0px !important;
        outline: unset !important;
        background: transparent !important;
        font-size: 12px;
        color: #CBD5E1 !important;
        position: relative;
        box-shadow: unset !important;
        max-width: 85px;
    }

        .custom-date-filter-container input::-webkit-inner-spin-button, .custom-date-filter-container input::-webkit-calendar-picker-indicator {
            /* display: none;
    -webkit-appearance: none; */
            background: transparent !important;
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
        }

.cdf-divider {
    height: 10px;
    width: 1px;
    background: #CBD5E1;
    margin-right: 15px;
}

.date-filter-container {
    display: flex;
    align-items: center;
    column-gap: 10px;
    overflow-x: scroll;
    padding-bottom: 15px;
    margin-bottom: 15px;
}

    .date-filter-container > div {
        white-space: nowrap;
        background: linear-gradient(0deg, rgba(25, 35, 47, 0.1), rgba(25, 35, 47, 0.1)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%, rgba(27, 42, 84, 0.3) 100%);
        box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
        border-radius: 20px;
        color: #898F97;
        padding: 8px 15px;
        font-size: 11px;
    }

    .date-filter-container .active {
        color: #3369FF;
        background: linear-gradient(0deg, rgba(51, 105, 255, 0.1), rgba(51, 105, 255, 0.1)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%, rgba(27, 42, 84, 0.3) 100%);
        box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
    }

.reload-list-item {
    background: linear-gradient(0deg, rgba(25, 35, 47, 0.1), rgba(25, 35, 47, 0.1)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%);
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    padding: 19px 20px;
    margin-top: 1.3em;
    font-size: 14px;
    color: #898F97;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

    .reload-list-item .first {
        display: flex;
        align-items: center;
        column-gap: 15px;
    }

.reload-modal-rejected-text {
    text-align: center;
    color: #FF334B;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
}

.reload-modal-approved-text {
    text-align: center;
    background: linear-gradient(0deg, rgba(164, 164, 164, 0.2), rgba(164, 164, 164, 0.2)), linear-gradient(318.37deg, rgba(50, 249, 0, 0.8) 0%, rgba(20, 239, 199, 0.8) 94.77%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
}

.reload-modal-amount {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #D9D9D9;
    text-align: center;
}

.reload-modal-date-balance-container > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #A4A4A4;
    font-size: 12px;
    line-height: 15px;
}

.custom-input-container .css-1pzqpnf-control {
    background: url("../../assets/img/v3/arrow-down.png");
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    border: 0px;
    width: 100%;
    font-size: 14px;
    color: #A4A4A4;
    height: 50px;
    padding: 0px;
    outline: unset;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: #132144;
    background-repeat: no-repeat;
    background-position: right 20px center;
    min-height: unset;
}

.custom-input-container .css-2xby0k-control {
    background: url("../../assets/img/v3/arrow-down.png");
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    border: 0px;
    width: 100%;
    font-size: 14px;
    color: #A4A4A4;
    height: 50px;
    padding: 0px;
    outline: unset;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: #132144;
    background-repeat: no-repeat;
    background-position: right 20px center;
    min-height: unset;
}

.custom-input-container .css-g1d714-ValueContainer {
    padding: 0px;
}

.custom-input-container .css-1hwfws3 {
    padding: 0px;
}

.custom-input-container .css-b8ldur-Input {
    padding: 0px;
    box-sizing: unset;
    margin: 0px;
}

    .custom-input-container .css-b8ldur-Input > div input {
        box-shadow: unset !important;
        border-radius: unset !important;
        height: unset !important;
        font-size: 14px !important;
        color: #A4A4A4 !important;
        padding: 0px 20px !important;
    }

.custom-input-container .css-1s8skx2-placeholder {
    padding: 0px 20px 0px;
    box-sizing: unset;
    margin: 0px;
    font-size: 14px;
    color: #A4A4A4;
}

.custom-input-container .css-1nynmi8-singleValue {
    padding: 0px 20px 0px;
    box-sizing: unset;
    margin: 0px;
    font-size: 14px;
    color: #A4A4A4;
    font-weight: 400;
}

.custom-input-container .css-1wy0on6 {
    display: none;
}

#select-member-id input {
    height: auto !important;
}

.custom-input-container .css-1okebmr-indicatorSeparator {
    display: none;
}

.custom-input-container .css-1hb7zxy-IndicatorsContainer {
    display: none;
}

.css-2613qy-menu {
    margin: 0px !important;
    padding: 0px !important;
}

.custom-input-container .form-group label {
    font-size: 16px;
    font-weight: 600;
    font-family: "Montserrat";
    padding: 0px;
    color: #fff;
}

.home-header.shared-header {
    padding: 20px 0px 30px;
    padding-top: 20px;
}

    .home-header.shared-header .top-header > div {
        line-height: 1;
        font-size: 16px;
        font-weight: 400 !important;
        font-family: "Montserrat";
        text-align: left;
    }

.box-list-with-shadow {
    background: linear-gradient(0deg, rgba(25, 35, 47, 0.2), rgba(25, 35, 47, 0.2)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%);
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    padding: 19px 20px;
    color: #898F97;
    margin-bottom: 1.5em;
    cursor: pointer;
}

.notification-list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    line-height: 1;
    column-gap: 15px;
    padding: 10px 20px;
}

    .notification-list-item .first {
        display: flex;
        align-items: center;
        column-gap: 20px;
    }

.notification-date {
    /* white-space: nowrap; */
    font-size: 10px;
    text-align: right;
}

.notification-list-item .second {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
}

.notification-read {
    width: 6px;
    height: 6px;
    background: linear-gradient(0deg, rgba(164, 164, 164, 0.2), rgba(164, 164, 164, 0.2)), linear-gradient(318.37deg, rgba(50, 249, 0, 0.8) 0%, rgba(20, 239, 199, 0.8) 94.77%);
    border-radius: 5px;
}

.notification-unread {
    width: 6px;
    height: 6px;
    background: #FF334B;
    border-radius: 5px;
}

.notification-title {
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    word-break: break-all;
}

.notification-details {
    font-size: 10px;
    line-height: 14px;
    word-break: break-all;
}

.notification-content-box {
    width: 170px;
}

.custom-date-filter-single-container {
    background: radial-gradient(90.16% 143.01% at 15.32% 21.04%, rgba(51, 105, 255, 0.2) 0%, rgba(255, 255, 255, 0.0447917) 77.08%, rgba(255, 255, 255, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
    background-blend-mode: overlay, normal;
    backdrop-filter: blur(6.07811px);
    border: 1px solid #ffffff2f;
    border-radius: 8px;
    padding: 0px 20px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

    .custom-date-filter-single-container .first {
        display: flex;
        align-items: center;
        width: 100%;
        position: relative;
    }

.custom-date-single-arrow-icon {
    position: absolute;
    top: -1px;
    right: 0px;
}

.custom-date-filter-single-container input {
    padding: 0px;
    border: 0px;
    outline: unset;
    background: transparent;
    font-size: 12px;
    color: #CBD5E1;
    position: relative;
    width: 100%;
}

    .custom-date-filter-single-container input::-webkit-inner-spin-button, .custom-date-filter-single-container input::-webkit-calendar-picker-indicator {
        /* display: none;
    -webkit-appearance: none; */
        background: transparent !important;
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
    }

.referral-header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
    margin-bottom: 30px;
}

    .referral-header-container > .first {
        font-size: 24px;
    }

    .referral-header-container > .second {
        display: flex;
        align-items: center;
        column-gap: 7px;
        font-size: 14px;
        background: linear-gradient(0deg, rgba(25, 35, 47, 0.2), rgba(25, 35, 47, 0.2)), linear-gradient(271.33deg, rgba(78, 75, 200, 0.3) 0%, rgba(254, 94, 171, 0.03) 100%);
        box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
        border-radius: 50px;
        padding: 7px 15px;
        line-height: 1;
    }

.custom-box-two-col-container {
    display: flex;
    align-items: center;
    column-gap: 15px;
}

    .custom-box-two-col-container > div {
        background: linear-gradient(0deg, rgba(25, 35, 47, 0.2), rgba(25, 35, 47, 0.2)), linear-gradient(271.33deg, rgba(78, 75, 200, 0.3) 0%, rgba(254, 94, 171, 0.03) 100%);
        box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
        border-radius: 8px;
        padding: 7px 10px;
        display: flex;
        align-items: center;
        column-gap: 10px;
        width: 50%;
    }

    .custom-box-two-col-container .custom-box-contents .title {
        font-size: 10px;
        color: #fff !important;
    }

    .custom-box-two-col-container .custom-box-contents .details {
        font-size: 12px;
        color: #fff !important;
    }

.hr-right {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    width: 80%;
    height: 1px;
    margin-left: auto;
    margin-right: -20px;
}

.hr-left {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    width: 80%;
    height: 1px;
    margin-left: -20px;
}

.referral-buttons > div {
    font-size: 11px;
    height: 55px;
    padding: 0px 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.referral-item-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 0px;
}

.swipeable-list-item__trailing-actions {
    display: flex;
    position: absolute;
    right: 0px;
    top: 0;
    justify-content: center;
    overflow: hidden;
    align-items: center;
    height: 58px;
    /* padding: 0px 20px; */
    min-width: 70px;
    background: #FF334B;
    border: 1px solid rgba(255, 73, 63, 0.4);
    border-radius: 8px;
    margin-top:1px;
    margin-right:1px;
    max-height: 57px;
    /* margin-right: 20px; */
}
/* .swipeable-list-item__trailing-actions .swipe-delete{
    padding: 0px 50px;
} */
.swipeable-list-item__content {
    position: relative;
    z-index: 1;
    background: #112349;
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    padding: 19px 20px;
    color: #898F97;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 1.5em;
}

.swipe-delete {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.ul-news-list.custom-dynamic-table-container .swipeable-list {
    height: unset;
}

    .ul-news-list.custom-dynamic-table-container .swipeable-list .swipeable-list-item__content {
        padding: 12px 20px;
    }

.ul-news-list.custom-dynamic-table-container .swipeable-list-item__trailing-actions {
    min-width: 75px;
    height: 75px;
}

.ul-news-list.custom-dynamic-table-container .align-items-center {
    padding: 0.5rem;
    width: 326px;
}

.ul-news-list .swipeable-list .btn {
    padding: 0.15rem 0.9rem;
    height: unset;
    margin-top: 0.2rem;
    border-color: #c62828;
}

.swipe-delete.downline-groups {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.home-wallet-balance:hover .tooltip-text {
    visibility: visible;
    top: 25px;
}

.home-wallet-balance-update:hover .tooltip-text {
    visibility: visible;
    top: 25px;
    width: 100px;
}

.referral-commission-item {
    background: linear-gradient(0deg, rgba(25, 35, 47, 0.2), rgba(25, 35, 47, 0.2)), linear-gradient(271.33deg, rgba(78, 75, 200, 0.3) 0%, rgba(254, 94, 171, 0.03) 100%);
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    column-gap: 5px;
}

    .referral-commission-item .first {
        font-size: 15px;
        width: 110px;
    }

    .referral-commission-item .second {
        width: 100%;
        display: flex;
        align-items: center;
        column-gap: 10px;
        justify-content: space-between;
    }

        .referral-commission-item .second .title {
            font-size: 10px;
            color: #A4A4A4;
            white-space: nowrap;
            font-style: italic;
        }

        .referral-commission-item .second .add-minus-box {
            display: flex;
            column-gap: 5px;
            align-items: center;
            background: linear-gradient(0deg, rgba(51, 105, 255, 0.1), rgba(51, 105, 255, 0.1)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%);
            box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
            border-radius: 30px;
            padding: 0px 10px;
            height: 40px;
        }

            .referral-commission-item .second .add-minus-box input {
                width: 50px;
                font-size: 12px;
                color: #FFFFFF;
                text-align: center;
                background: transparent;
                outline: unset;
                box-shadow: unset;
                border: 0px;
            }

        .referral-commission-item .second .btn-max {
            font-size: 10px;
            color: #FFFEFE;
            width: 40px;
            height: 40px;
            border-radius: 100px;
            background: linear-gradient(0deg, rgba(51, 105, 255, 0.5), rgba(51, 105, 255, 0.5)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%);
            box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
            display: flex;
            align-items: center;
            justify-content: center;
        }

.rc-no-bg {
    background: transparent;
    box-shadow: unset;
}

.referral-commission-item .rc-game-name {
    font-size: 12px;
    font-family: "poppins-regular";
}

.referral-group-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
}

.referral-group-count {
    font-size: 11px;
}

.referral-details-qr-box {
    display: flex;
    column-gap: 30px;
    justify-content: space-between;
}

    .referral-details-qr-box .first {
        color: #A4A4A4;
        width: 100%;
    }

        .referral-details-qr-box .first a {
            color: #3369FF;
            text-decoration: underline;
        }

    .referral-details-qr-box .second {
        background: linear-gradient(0deg, rgba(25, 35, 47, 0.2), rgba(25, 35, 47, 0.2)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%);
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15));
        border-radius: 8px;
        padding: 8px 11px;
        box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
        display: flex;
        align-items: center;
        justify-content: center;
    }

.referral-code-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: linear-gradient(0deg, rgba(25, 35, 47, 0.2), rgba(25, 35, 47, 0.2)), linear-gradient(271.33deg, rgba(78, 75, 200, 0.3) 0%, rgba(254, 94, 171, 0.03) 100%);
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    color: #fff;
    width: 100%;
    padding: 8px 20px;
    font-weight: bold;
}

  .referral-code-box > div.remark {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 150px;
  }

.referral-group-details-item {
    display: flex;
    align-items: center;
    column-gap: 20px;
}

    .referral-group-details-item .first {
        background: linear-gradient(221.94deg, rgba(77, 49, 233, 0.4) 0%, rgba(216, 104, 255, 0) 99.53%);
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15));
        border-radius: 50px;
        width: 35px;
        height: 35px;
        min-width: 35px;
        min-height: 35px;
        align-items: center;
        justify-content: center;
        display: flex;
    }

    .referral-group-details-item .second {
        font-size: 14px;
    }

        .referral-group-details-item .second .referral-group-details-number {
            color: #898F97;
            font-size: 11px;
        }

.referral-group-details-container .custom-checkbox span {
    background: transparent;
    border: 2px solid #A4A4A499;
}

.referral-group-details-container .custom-checkbox {
    display: flex;
    align-items: center;
}

.referral-members-added-container {
    display: flex;
    align-items: center;
    overflow-x: auto;
    padding-bottom: 10px;
    column-gap: 10px;
}

    .referral-members-added-container .referral-group-details-item {
        background: linear-gradient(0deg, rgba(25, 35, 47, 0.2), rgba(25, 35, 47, 0.2)), linear-gradient(271.33deg, rgba(78, 75, 200, 0.3) 0%, rgba(254, 94, 171, 0.03) 100%);
        border: 2px solid transparent;
        box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
        border-radius: 8px;
        padding: 5px 10px;
    }

        .referral-members-added-container .referral-group-details-item.new-added {
            border: 2px solid #3369FF;
        }

        .referral-members-added-container .referral-group-details-item .second {
            white-space: nowrap;
        }

        .referral-members-added-container .referral-group-details-item .referral-group-details-number {
            white-space: nowrap;
        }

.sweet-alert h2 {
    color: #ffffff;
}

.live-chat-item {
    display: flex;
    align-items: center;
    column-gap: 30px;
    padding: 0 20px;
    height: 55px;
}

.video-item-box {
    background: linear-gradient(0deg, rgba(25, 35, 47, 0.1), rgba(25, 35, 47, 0.1)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%);
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    padding: 15px 15px;
    padding-bottom: 0px;
}

.video-tutorial-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 20px;
    padding: 10px 0px 15px;
}

    .video-tutorial-content .first {
        display: flex;
        align-items: center;
        column-gap: 20px;
    }

    .video-tutorial-content .video-name {
        font-size: 14px;
        color: #898F97 !important;
        /* font-weight: 400; */
    }

    .video-tutorial-content .video-provider {
        font-size: 10px;
        color: #898F97;
        /* font-weight: 400; */
    }

    .video-tutorial-content .custom-icon-box {
        height: 38px;
        padding: 7px;
    }

.ul-news-list li {
    background: #112349;
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    color: #898F97;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 1.5em;
}

    .ul-news-list li > a {
        padding: 20px 20px 20px 0;
        color: #898F97;
        font-size: 14px;
    }

.ul-news-list .referral-delete-icon {
    width: auto;
    padding: 0px;
}

.edit-referral-table .data-row {
    background: transparent;
    box-shadow: unset;
    padding: 10px 20px;
    justify-content: space-between;
}

    .edit-referral-table .data-row.category {
        background: linear-gradient(0deg, rgba(25, 35, 47, 0.2), rgba(25, 35, 47, 0.2)), linear-gradient(271.33deg, rgba(78, 75, 200, 0.3) 0%, rgba(254, 94, 171, 0.03) 100%);
        box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
        border-radius: 8px;
        padding: 10px 20px;
        margin-top: 0px !important;
    }

#referral-detail-modern .edit-referral-table .row-data-1 {
    font-size: 15px !important;
    width: 70px;
}

#referral-detail-modern .edit-referral-table .row-data-title {
    font-size: 12px;
    font-family: Montserrat;
    word-break: break-all;
}

.row-data-2 .txt-1 {
    font-size: 9px;
    color: #A4A4A4;
    font-style: italic;
    width: 55px;
}

.row-data-2 .txt-2 {
    font-size: 8px;
    color: #A4A4A4;
    white-space: nowrap;
    font-style: italic;
}

.row-data-2 {
    width: auto !important;
    padding-right: 10px;
    max-width: 55px;
}

.add-minus-box {
    display: flex;
    column-gap: 5px;
    align-items: center;
    background: linear-gradient(0deg, rgba(51, 105, 255, 0.1), rgba(51, 105, 255, 0.1)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%);
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
    border-radius: 30px;
    padding: 0px 10px;
    height: 40px;
}

    .add-minus-box input {
        width: 50px;
        font-size: 12px;
        color: #FFFFFF;
        text-align: center;
        background: transparent;
        outline: unset;
        box-shadow: unset;
        border: 0px;
    }

.bonusComm-option-select {
    width: 30px;
    padding: 0px;
    background: transparent !important;
    border: 0px !important;
    outline: unset !important;
    color: #fff;
    text-align: center;
}

.row-data-3 {
    width: auto !important;
}

#referral-detail-modern .row-data-max {
    font-size: 10px;
    color: #FFFEFE;
    width: 40px;
    height: 40px;
    border-radius: 100px !important;
    background: linear-gradient(0deg, rgba(51, 105, 255, 0.5), rgba(51, 105, 255, 0.5)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%) !important;
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3) !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0px
}

.custom-input-container .form-group {
    margin-bottom: 0px;
}

.modal-content.edit-downline-modal .modal-body {
    overflow-x: hidden;
    overflow-y: auto;
}

.modal-content.edit-downline-modal.modern {
    background: radial-gradient(90.16% 143.01% at 15.32% 21.04%, rgba(15, 36, 94, 0.15) 0%, rgba(255, 51, 75, 0.012) 77.08%, rgba(6, 22, 65, 0.15) 100%);
    -webkit-backdrop-filter: blur(35px);
    backdrop-filter: blur(35px);
    border-radius: 50px 50px 0px 0px;
    border: 0px;
}

.btn-red {
    background: rgb(224, 53, 53);
}

.referral-link-wrapper input {
    background-color: #132144 !important;
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
    border-radius: 8px !important;
    color: #A4A4A4;
}

.referral-copy-btn {
    padding-top: 10px;
}

.referral-nav .nav.nav-tabs .nav-item .nav-link.active {
    background: linear-gradient(0deg, rgba(51, 105, 255, 0.1), rgba(51, 105, 255, 0.1)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%);
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
    color: #fff;
}

.table-rollover tr td, .table-rollover tr th {
    color: #fff;
}

#rollover .table-rollover tr td, .table-rollover tr th {
    color: #0D1A3B;
}

#referral-detail-modern h4 {
    color: #fff !important;
}

.provider-image {
    max-height: 100%;
    max-width: 100%;
    padding: 4px;
}

.flex-baseline {
    display: flex;
    align-items: baseline;
}

.claim-commission-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: linear-gradient(180deg, rgba(51, 105, 255, 0.24) -6.16%, rgba(27, 42, 84, 0.24) 110.96%);
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3), inset 0px 1.5px 0.5px rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    padding: 15px 15px;
}

    .claim-commission-container .first {
        display: flex;
        align-items: center;
        column-gap: 15px;
    }

    .claim-commission-container .custom-icon-box {
        padding: 8px;
    }

    .claim-commission-container .second {
        display: flex;
        margin-left: 10px;
        background: #3369ff;
        border-radius: 20px;
        padding: 8px 25px;
        font-size: 12px;
        align-items: center;
        line-height: 1;
        color: #fff;
        border: unset;
    }

        .claim-commission-container .second:disabled {
            background: #a4a4a457;
        }

/* .claim-commission-item{
    padding: 10px 20px;
    background: #112349 !important;
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
} */
.claim-commission-item .comm-item button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background: transparent;
    outline: unset;
    border: 0px;
    color: #898F97;
    text-align: left;
    padding: 10px 20px;
    background: #112349;
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    color: #898F97;
}

.claim-commission-item .comm-data-drop {
    border-radius: 0px 0px 8px 8px;
    background: #142655;
    padding: 10px 20px;
}

.claim-commission-item .accordion-body:before {
    display: block;
    content: ' ';
    width: calc(100% - 20px);
    height: 1px;
    background: #898F97;
    margin: auto;
}

.claim-commission-item {
    background: #142655 !important;
}

    .claim-commission-item .accordion-collapse {
        padding-top: 20px;
        margin-top: -20px;
    }

    .claim-commission-item .comm-item button[aria-expanded="true"] {
        background: #142655;
        box-shadow: unset;
        border-radius: 8px 8px 0px 0px;
    }

    .claim-commission-item.active {
        background: linear-gradient(0deg, rgba(51, 105, 255, 0.1), rgba(51, 105, 255, 0.1)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%);
        box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
    }

    .claim-commission-item .comm-item {
        margin-bottom: 0px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

        .claim-commission-item .comm-item .first {
            font-size: 14px;
        }

            .claim-commission-item .comm-item .first .date-text {
                font-size: 10px;
            }

.red-label-box {
    font-size: 9px;
    background: #FF334B;
    border-radius: 20px;
    padding: 3px;
    color: #fff;
    width: 70px;
    text-align: center !important;
    display: inline-block;
}

.green-label-box {
    font-size: 9px;
    background: linear-gradient(0deg, rgba(164, 164, 164, 0.2), rgba(164, 164, 164, 0.2)), linear-gradient(318.37deg, rgba(50, 249, 0, 0.8) 0%, rgba(20, 239, 199, 0.8) 94.77%);
    border-radius: 20px;
    padding: 3px;
    color: #fff;
    width: 70px;
    text-align: center !important;
    display: inline-block;
}

.comm-details {
    border-top: 1px solid #898f974d;
    padding-top: 10px;
    margin-top: 10px;
    font-size: 14px;
    display: none;
}

    .comm-details > div {
        margin-bottom: 5px;
    }

.no-data {
    border-top: 1px solid;
    padding-top: 10px;
    color: #898F97;
    font-size: 10px;
    text-align: center;
}

.commission-history-container .css-16hr6bx-control {
    background: radial-gradient(90.16% 143.01% at 15.32% 21.04%, rgba(51, 105, 255, 0.2) 0%, rgba(255, 255, 255, 0.0447917) 77.08%, rgba(255, 255, 255, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
    background-blend-mode: overlay, normal;
    backdrop-filter: blur(6.07811px);
    border: 1px solid #cbcbcb26;
    color: #fff;
    border-radius: 8px;
    height: 40px;
    padding: 0px 20px;
}

.commission-history-container .css-bbcqiw-control {
    background: radial-gradient(90.16% 143.01% at 15.32% 21.04%, rgba(51, 105, 255, 0.2) 0%, rgba(255, 255, 255, 0.0447917) 77.08%, rgba(255, 255, 255, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
    background-blend-mode: overlay, normal;
    backdrop-filter: blur(6.07811px);
    border: 1px solid #cbcbcb26;
    color: #fff;
    border-radius: 8px;
    height: 40px;
    padding: 0px 20px;
}

.commission-history-container .css-1bxi0x5-singleValue {
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    padding: 0px;
}

.commission-history-container .css-g1d714-ValueContainer {
    /* padding-left:0px; */
    height: 25px;
}

.commission-history-container .form-group input {
    height: auto;
}

.commission-history-container .css-tlfecz-indicatorContainer {
    /* padding:0px; */
}

.commission-history-container .css-1gtu0rj-indicatorContainer {
    padding: 0px;
}

.commission-history-container .css-1okebmr-indicatorSeparator {
    display: none;
}

.form-group label {
    font-size: 16px;
    font-weight: 600;
    font-family: "Montserrat";
    color: #fff;
    padding: 0px;
    margin-bottom: 16px !important;
}

input.form-control:focus-visible {
    outline: none;
}

.form-control:focus {
    color: unset;
}

.vers-num {
    font-size: 9px;
    font-weight: 100;
    margin-left: 15px;
}

.VPower-lobby .load-table-wrapper {
    grid-template-columns: repeat(2,1fr);
}

.top-right-menu {
    display: flex;
    align-items: center;
    width: 50px;
    justify-content: center;
    padding: 10px 0;
}

.top-right-menu-dropdown {
    position: absolute;
    top: 50px;
    right: 5px;
    z-index: 999;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: #393155;
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    min-width: 150px;
    overflow: hidden;
}

    .top-right-menu-dropdown .dropdown-option {
        padding: 15px 15px;
        width: 100%;
        color: rgba(255, 255, 255, 0.6);
        font-size: 12px;
        font-weight: 400;
    }

        .top-right-menu-dropdown .dropdown-option.dark {
            background-color: #31376e;
        }

        .top-right-menu-dropdown .dropdown-option.light {
            background: #3c3D6f;
        }

    .top-right-menu-dropdown .dropdown-option {
        padding: 10px;
    }


.ul-news-list #delReferral {
    padding: 20px;
}

.custom-input-container .internal-transfer-dropdown {
    display: none !important;
}

.search-input-box .input-group {
    background: radial-gradient(90.16% 143.01% at 15.32% 21.04%, rgba(51, 105, 255, 0.2) 0%, rgba(255, 255, 255, 0.0447917) 77.08%, rgba(255, 255, 255, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
    background-blend-mode: overlay, normal;
    -webkit-backdrop-filter: blur(6.07811px);
    backdrop-filter: blur(6.07811px);
    border: 1px solid #cbcbcb26;
    color: #3369FF;
    font-size: 12px;
    font-family: "poppins-medium";
    border-radius: 8px;
    padding: 0px 20px;
    width: 100%;
    height: 40px;
}

.search-input-box input {
    height: unset;
    padding: 0px;
    background: transparent !important;
    box-shadow: unset;
    color: #3369FF !important;
    font-size: 12px;
    font-family: "poppins-medium";
}

.search-input-box .input-group-append {
    position: absolute;
    background: transparent;
    width: 50px;
    right: 0px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.search-input-box .input-group-text {
    padding: 0px !important;
    background: transparent !important;
}

.custom-dynamic-table-container.ul-news-list li {
    padding: 12px 20px;
}

.ul-news-list > li:first-of-type {
    border-radius: 8px !important;
}

.ul-news-list > li:last-of-type {
    border-radius: 8px !important;
}

.hide-summary-box {
    display: flex;
    align-items: center;
    column-gap: 15px;
}

.custom-dynamic-table-container.ul-news-list li > a {
    padding: 0px;
}

.nav.nav-tabs + .downline-tab-content {
    padding: 0px;
}

.downline-nav {
    display: flex;
    align-items: center;
    background: linear-gradient(180deg, rgba(51, 105, 255, 0.24) -6.16%, rgba(27, 42, 84, 0.24) 110.96%);
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3), inset 0px 1.5px 0.5px rgba(255, 255, 255, 0.1);
    border-radius: 50px;
    font-size: 12px;
    padding: 5px;
}

    .downline-nav .nav-item {
        width: 50%;
    }

  .downline-nav.modern .nav-item .nav-link,
  .downline-nav.classic .nav-item .nav-link {
    text-align: center;
    border-radius: 30px;
    height: 42px;
    align-items: center;
    justify-content: center;
    display: flex;
    border-bottom: none !important;
  }

    .downline-nav .nav-item .nav-link.active {
        background: linear-gradient(0deg, rgba(51, 105, 255, 0.1), rgba(51, 105, 255, 0.1)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%);
        box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
        border: 0px !important;
        color: #fff !important;
    }

.custom-box-content-with-shadow {
    background: linear-gradient(0deg, rgba(25, 35, 47, 0.2), rgba(25, 35, 47, 0.2)), linear-gradient(271.33deg, rgba(78, 75, 200, 0.3) 0%, rgba(254, 94, 171, 0.03) 100%);
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
    padding: 15px 15px;
    border-radius: 8px;
    font-size: 12px;
}

.downline-stat-wrapper .card-brand {
    background: linear-gradient(0deg, rgba(25, 35, 47, 0.2), rgba(25, 35, 47, 0.2)), linear-gradient(271.33deg, rgba(78, 75, 200, 0.3) 0%, rgba(254, 94, 171, 0.03) 100%);
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
    padding: 15px 10px;
    border-radius: 8px;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
}

.downline-stat-wrapper .card-direct-downline .title {
    color: #FFD928;
    font-size: 26px;
}

.downline-stat-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
    margin-bottom: 0px;
}

    .downline-stat-wrapper .second .card-brand {
        font-size: 12px;
    }

.btn.btn-brand {
    /* display: flex; */
    /* margin-left: 10px; */
    background: #3369ff !important;
    border-radius: 20px;
    padding: 8px 15px;
    font-size: 10px;
    color: #fff;
}

#downline-modern .tab-pane.active .ul-news-list, #downline .tab-pane.active .ul-news-list {
    height: 65vh;
}

.pin-number {
    padding: 40px 0 0 0;
    max-width: 500px;
    margin: auto;
    background: radial-gradient(90.16% 143.01% at 15.32% 21.04%, rgba(15, 36, 94, 0.15) 0%, rgba(255, 51, 75, 0.012) 77.08%, rgba(6, 22, 65, 0.15) 100%);
    -webkit-backdrop-filter: blur(35px);
    backdrop-filter: blur(35px);
    border-radius: 65px 65px 0px 0px;
    border: 0px;
    width: 100%;
}

.pin-wrapper {
    background: transparent;
}

.input.pincode-input-text {
    border-bottom-color: #fff !important;
    justify-content: flex-end;
}

.vip-event-accordion.accordion .accordion-header .accordion-button {
    background: #112349;
    border-radius: 8px !important;
    color: #898F97;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border: 0px;
    padding: 12px 20px;
    font-size: 14px;
}

.shareholder-data {
    display: flex;
    justify-content: space-between;
    padding: 5px 20px;
}

.edit-sh-btn {
    text-align: center;
    text-decoration: underline;
    padding: 10px 0;
}

/* .modal-edit-sh {
    background: radial-gradient(90.16% 143.01% at 15.32% 21.04%,rgba(15,36,94,.15) 0,rgba(255,51,75,.012) 77.08%,rgba(6,22,65,.15) 100%);
    backdrop-filter: blur(35px);
} */
.vip-event-accordion.accordion h2 {
    margin-bottom: 0px;
}

.vip-event-accordion.accordion .accordion-header .accordion-button[aria-expanded="true"] {
    background: #142655;
    box-shadow: unset;
    border-radius: 8px 8px 0px 0px;
}

.vip-event-downline-data {
    border-radius: 0px 0px 8px 8px;
    background: #142655;
    margin-top: 10px;
}

.accordion-item {
    background: transparent;
}

.vip-event-accordion .accordion-collapse {
    padding-top: 20px;
    margin-top: -20px;
}

.vip-event-accordion .accordion-item {
    border-radius: 8px;
    background: #142655;
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
}

.shareholder-data {
    color: #898F97;
    font-size: 12px;
    font-weight: 400;
}

.vip-event-accordion.accordion .accordion-body:before {
    display: block;
    content: ' ';
    width: calc(100% - 20px);
    height: 1px;
    background: #898F97;
    margin: auto;
}

.edit-sh-btn {
    color: #3369FF;
    font-size: 11px;
    font-weight: 400;
}

.edit-sh-data .shareholder-data {
    color: #A4A4A4;
}

.edit-vt-btn {
    color: #3369FF;
    font-size: 11px;
    font-weight: 400;
}

.edit-vt-data .video-data {
    color: #A4A4A4;
}

.video-data {
    color: #898F97;
    font-size: 12px;
    font-weight: 400;
}

.video-data {
    display: flex;
    justify-content: center;
    padding: 10px 20px;
}

.video-url {
    text-align: center;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropbtn {
    background-color: #3498DB;
    color: white;
    padding: 16px;
    font-size: 16px;
    border: none;
    cursor: pointer;
}

    .dropbtn:hover, .dropbtn:focus {
        background-color: #2980B9;
    }

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    overflow: auto;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
}

    .dropdown-content a {
        color: black;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
    }

.dropdown a:hover {
    background-color: #ddd;
}

.show {
    display: block;
}

.shareholder-hide-summary {
    justify-content: flex-start;
    align-items: center;
    column-gap: 15px;
}

.sh-min-max {
    color: #FF334B;
    font-size: 11px;
}

.sh-select-box {
    border: 1px solid #898F97;
    padding: 5px 10px;
    border-radius: 100px;
}

.sh-select {
    border: 0px solid #898F97;
    background: transparent;
    outline: unset !important;
    color: #3369FF;
    font-size: 15px;
}

.vip-claim-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    line-height: 1;
    grid-column-gap: 15px;
    column-gap: 15px;
    margin-top: 0px;
    margin-bottom: 1.5em;
}

.downline-accordion .accordion-button {
    background: #112349;
    border-radius: 8px;
    color: #898F97;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border: 0px;
    padding: 12px 20px;
    font-size: 14px;
}

    .downline-accordion .accordion-button[aria-expanded="true"] {
        background: #142655;
        box-shadow: unset;
        border-radius: 8px 8px 0px 0px;
    }

.downline-accordion .accordion-body:before {
    display: block;
    content: ' ';
    width: calc(100% - 20px);
    height: 1px;
    background: #898F97;
    margin: auto;
}

.downline-accordion .accordion-item {
    border-radius: 8px;
    background: #142655;
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
}

.downline-accordion h2 {
    margin-bottom: 0px;
}

.downline-data-wrapper {
    display: flex;
    flex-direction: column;
    color: #898F97;
    font-size: 13px;
    font-weight: 400;
    padding: 10px 15px;
}

.downline-data {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
}

.downline-comm-rate .data-right {
    font-weight: bold;
}

.edit-downline-btn {
    color: #3369FF;
}

.edit-downline-btn {
    text-align: center;
    padding: 10px 0 5px 0;
    text-decoration: underline;
}

.custom-tables .card-wrapper {
    background: linear-gradient(0deg, rgba(25, 35, 47, 0.2), rgba(25, 35, 47, 0.2)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%);
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    overflow: hidden;
    padding: 0px;
}

.custom-tables .card {
    background: transparent;
    box-shadow: unset;
}

.custom-tables .card-table .table th {
    color: #898F97;
    white-space: nowrap;
    font-size: 12px;
    font-weight: 400;
}

.custom-tables .card-table .table td, .card-table .table th {
    color: #898F97;
    border-color: #898F97;
    white-space: nowrap;
    font-size: 12px;
    font-weight: 500;
}

.custom-tables .card-table thead {
    border-bottom: 2px solid #898F97 !important;
}

.custom-tables .card-table .table {
    overflow-x: auto;
}

.shareholder-my-income .content-title {
    color: #fff;
    font-size: 9px;
    margin-bottom: 2px;
}

.shareholder-my-income .content-value {
    color: #3369FF;
    font-size: 12px;
}

.my-income-card-content {
    padding: 5px 0px;
}

.custom-tables .card-table .table .has-downline {
    color: #3369FF;
}

.card-package .table .subtotal td {
    background: #1A2860;
}

.card-package .table .grandtotal td {
    background: #1A2860;
}

.vip-main-nav {
    display: flex;
    column-gap: 20px;
}

    .vip-main-nav > div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50%;
        background: linear-gradient(180deg, rgba(51, 105, 255, 0.24) -6.16%, rgba(27, 42, 84, 0.24) 110.96%);
        box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3), inset 0px 1.5px 0.5px rgba(255, 255, 255, 0.1);
        border-radius: 8px;
        height: 50px;
    }

.h3-with-arrow {
    display: flex;
    align-items: center;
    column-gap: 15px;
}

.pcr-list-card {
    background: #112349;
    padding: 10px 25px;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    margin: 15px 0;
}

.pcr-card-header {
    font-size: 16px;
    font-weight: bold;
}

.pcr-card-data {
    display: flex;
    flex-direction: column;
}

.card-data-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    color: #A4A4A4;
    margin-top: 8px;
}

.pcr-list-wrapper {
    padding-bottom: 15px;
}

#game-history-modern .form-group,
#game-history .form-group {
    margin-bottom: 0;
}

    #game-history-modern .form-group > div > div,
    #game-history .form-group > div > div {
        height: unset;
    }

#game-history-modern .card-package,
#game-history .card-package {
    margin-top: 1.4rem;
}

#bonus-history-modern .form-group,
#bonus-history .form-group {
    margin-bottom: 0;
}

    #bonus-history-modern .form-group > div > div > div,
    #bonus-history .form-group > div > div > div {
        height: unset;
    }

#bonus-history-modern .card-package,
#bonus-history .card-package {
    margin-top: 1.4rem;
}

.form-group .mb-0-imp {
    margin-bottom: 0px !important;
}

.downline-product-rate .home-header.shared-header {
    padding-bottom: 14px;
}

.referral-icons-wrapper {
    display: block;
    align-items: unset;
    justify-content: unset;
    position: fixed;
    width: 100%;
    left: 0px;
    bottom: 0px;
    padding: 40px 20px 40px;
    max-width: 500px;
    margin: auto;
    background: #252a5229;
    -webkit-backdrop-filter: blur(35px);
    backdrop-filter: blur(35px);
    border-radius: 65px 65px 0px 0px;
    border: 0px;
    width: 100%;
}

    .referral-icons-wrapper .st-inline-share-buttons {
        width: auto;
    }

/* GAME POINTS SECTION START */

.game-points-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: linear-gradient(180deg, rgba(51, 105, 255, 0.24) -6.16%, rgba(27, 42, 84, 0.24) 110.96%);
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3), inset 0px 1.5px 0.5px rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    padding: 15px 15px;
}

    .game-points-container.section1 {
        padding: 10px 15px;
    }

    .game-points-container .title {
        font-size: 12px;
        color: #fff;
    }

    .game-points-container .details {
        font-size: 14px;
        color: #fff;
        font-weight: bold;
    }

    .game-points-container .first {
        display: flex;
        align-items: center;
        column-gap: 15px;
    }

    .game-points-container .amount {
        color: #3369FF;
        font-size: 14px;
        font-weight: bold;
    }

    .game-points-container .custom-icon-box {
        padding: 8px 5px;
    }

    .game-points-container .second {
        display: flex;
        margin-left: 10px;
        background: #3369ff;
        border-radius: 20px;
        padding: 8px 25px;
        font-size: 12px;
        align-items: center;
        line-height: 1;
        color: #fff;
        border: unset;
    }

        .game-points-container .second:disabled {
            background: dimgrey;
        }

.game-pts-item-card {
    border-radius: 10px;
    /* height: 250px; */
    background-color: #0D1A3B;
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3), inset 0px 1.5px 0.5px rgba(255, 255, 255, 0.1);
    background: linear-gradient(180deg, rgba(51, 105, 255, 0.24) -6.16%, rgba(27, 42, 84, 0.24) 110.96%);
    padding: 10px 10px;
}

    .game-pts-item-card .game-pts-item-img {
        border-radius: 10px 10px 0 0;
        width: 100%;
    }

  .game-pts-item-card .game-pts-item-card-body {
    padding: 0px;
    display: flex;
    flex-direction: column;
    justify-content: end;
    padding: 10px;
  }

    .game-pts-item-card .game-pts-item-card-body .game-pts-item-title {
      margin-bottom: 5px;
      color: #FFFFFF;
      font-size: 11px;
      min-height: 30px;
      text-align: left;
      /* font-weight: bold; */
    }

    .game-pts-item-card .game-pts-item-card-body .game-pts-item-text {
      /* margin-bottom: 5px; */
      color: #3369FF;
      font-size: 10px;
      text-align: left;
    }

    .game-pts-item-card .game-pts-item-card-body .btn-brand-gradient {
      font-size: 12px;
      border-radius: 8px;
      height: 25px;
    }

  .game-pts-item-card .game-pts-item-img .game-pts-btn-disabled {
    width: 100%;
    border-radius: 10px;
    cursor: not-allowed;
  }

.btn-container .btn-brand-gradient {
  font-size: 14px;
  border-radius: 15px;
}

.claim-game-point-item {
  padding: 10px 20px;
}

    .claim-game-point-item .game-point-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

        .claim-game-point-item .game-point-item .first {
            font-size: 14px;
        }

        .claim-game-point-item .game-point-item .date-text {
            font-size: 10px;
        }

    .claim-game-point-item .red-label-box {
        font-size: 9px;
        background: #FF334B;
        border-radius: 20px;
        padding: 3px;
        color: #fff;
        width: 70px;
        text-align: center;
    }

    .claim-game-point-item .green-label-box {
        font-size: 9px;
        background: linear-gradient(0deg, rgba(164, 164, 164, 0.2), rgba(164, 164, 164, 0.2)), linear-gradient(318.37deg, rgba(50, 249, 0, 0.8) 0%, rgba(20, 239, 199, 0.8) 94.77%);
        border-radius: 20px;
        padding: 3px;
        color: #fff;
        width: 70px;
        text-align: center;
    }

/* GAME POINTS SECTION END */
.points-list-title {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.transform-1 {
    transform: translate3d(-80px, 25px, 0px) !important;
    min-width: 100px;
    background-color: #3c3d6f;
}


.transform-button .btn.btn-secondary {
    border: 0px !important;
    background-color: #0D1A3B !important;
    background: linear-gradient(0deg, rgb(0 0 0 / 0%), rgb(0 0 0 / 0%)), linear-gradient(271.54deg, rgb(0 0 0 / 0%) -18.33%, rgb(0 0 0 / 0%) 100%) !important;
}

.report-menu .card-panel {
    background: #112349;
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
    padding: 0px !important;
}

.card.card-package .card-body .table {
    background: rgb(100 94 94 / 30%);
    /* backdrop-filter: blur(35px); */
    color: #A4A4A4;
    border-radius: 15px;
}

.container .card {
    background-color: transparent !important;
}

#rollover.container .card {
    background-color: white !important;
}

.kpi-total-container {
    display: flex;
    column-gap: 16px;
    flex-wrap: wrap;
}

    .kpi-total-container > div {
        width: calc(50% - 8px);
    }

    .kpi-total-container .first {
        background: linear-gradient(0deg, rgba(25, 35, 47, 0.2), rgba(25, 35, 47, 0.2)), linear-gradient(271.33deg, rgba(78, 75, 200, 0.3) 0%, rgba(254, 94, 171, 0.03) 100%);
        box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
        padding: 10px 10px;
        border-radius: 8px;
        color: #fff;
    }

    .kpi-total-container .second {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        /* align-items: center; */
        flex: 0 0 auto;
    }

.kpi-second-child {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    background: linear-gradient(0deg, rgba(25, 35, 47, 0.2), rgba(25, 35, 47, 0.2)), linear-gradient(271.33deg, rgba(78, 75, 200, 0.3) 0%, rgba(254, 94, 171, 0.03) 100%);
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
    padding: 12px 10px;
    border-radius: 8px;
    color: #fff;
    overflow-x: auto;
    column-gap: 10px;
}

    .kpi-second-child > div:first-child {
        white-space: nowrap;
    }

.title-kpi {
    font-size: 12px;
    margin-bottom: 5px;
}

.table-kpi {
    font-size: 11px;
    color: #898F97;
    margin-bottom: 0px;
}

    .table-kpi tr td, .table-kpi tr th {
        font-weight: 400;
        color: #898F97;
    }

.custom-select-box .css-tlfecz-indicatorContainer {
    color: #3369FF;
    padding-right: 14px;
}

.custom-select-box .css-1gtu0rj-indicatorContainer {
    color: #3369FF;
    padding-right: 14px;
}

.custom-select-box > div > div {
    background: #15295f;
    border: 1px solid #ffffff2f;
    border-radius: 8px;
    cursor: pointer;
}

   /* .custom-select-box > div > div:last-child {
        background: #15295f;
        border-radius: 8px;
        opacity: 1;
    }*/

.custom-select-box > div > div > div > div {
    color: #898F97;
}

.kpi-select .css-g1d714-ValueContainer {
    padding-left: 20px;
}

.card-package {
    background: transparent;
}

    .card-package .card-body {
        background: linear-gradient(0deg, rgba(25, 35, 47, 0.2), rgba(25, 35, 47, 0.2)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%);
        box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
        border-radius: 8px;
        /* overflow: hidden; */
}

.card.card-package .card-body .table {
    background: transparent;
}

.table-kpi-summary td {
    border-top: 0px;
}

.card-table .table th {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
}

.card-package .table th, .card-package .table td {
    color: #898F97;
    font-size: 10px !important;
    font-family: "Montserrat";
    font-weight: 400;
    white-space: nowrap;
}

.table-kpi-summary tfoot {
    background: #1b2961;
}

.card-table {
    border-radius: 8px;
}

.card.card-package .card-body .table {
    border-radius: 8px;
}

.card-package .table th {
    white-space: nowrap;
}

.shareholder-accordion-header .accordion-button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.rewards-claim-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: linear-gradient(180deg, rgba(51, 105, 255, 0.24) -6.16%, rgba(27, 42, 84, 0.24) 110.96%);
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3), inset 0px 1.5px 0.5px rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    padding: 15px 15px;
}

    .rewards-claim-container button {
        display: flex;
        margin-left: 10px;
        background: #3369ff;
        border-radius: 20px;
        padding: 8px 25px;
        font-size: 12px;
        align-items: center;
        line-height: 1;
        color: #fff;
        border: unset;
    }

    .rewards-claim-container .first .title {
        font-size: 12px;
        font-weight: 400;
        color: #fff;
    }

    .rewards-claim-container .first .details {
        font-size: 14px;
        font-weight: bold;
        display: flex;
        align-items: center;
        column-gap: 15px;
    }

.custom-two-col-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 15px;
}

    .custom-two-col-box > div {
        background: linear-gradient(180deg, rgba(51, 105, 255, 0.24) -6.16%, rgba(27, 42, 84, 0.24) 110.96%);
        box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3), inset 0px 1.5px 0.5px rgba(255, 255, 255, 0.1);
        border-radius: 8px;
        padding: 10px 15px;
        width: 50%;
        text-align: center;
    }

        .custom-two-col-box > div .title {
            font-size: 12px;
            font-weight: 400;
            color: #fff;
        }

        .custom-two-col-box > div .details {
            font-size: 12px;
            font-weight: 400;
            color: #fff;
        }

.rewards-calendar {
    background: linear-gradient(0deg, rgba(25, 35, 47, 0.1), rgba(25, 35, 47, 0.1)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%);
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    color: #fff;
    font-size: 14px;
    font-family: "Montserrat";
    font-weight: 400;
}

.rewards-calendar-month {
    font-size: 14px;
}

.rewards-calendar-day {
    color: #898F97;
    font-size: 12px;
}

    .rewards-calendar-day.active {
        background: #3369FF;
        color: #fff;

    }

.rewards-game-item {
    background: linear-gradient(0deg, rgba(25, 35, 47, 0.2), rgba(25, 35, 47, 0.2)), linear-gradient(271.33deg, rgba(78, 75, 200, 0.3) 0%, rgba(254, 94, 171, 0.03) 100%);
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    margin: 0px 5px;
    padding: 8px 8px;
    margin-bottom: 20px;
}

.rewards-game-list-container {
    display: grid;
    grid-template-columns: 33.33% 33.33% 33.33%;
    margin: 0px -5px;
    text-align: center;
}

.rewards-game-item .title {
    color: #fff;
    font-size: 12px;
}

.rewards-game-item .details {
    color: #3369FF;
    font-size: 12px;
    font-weight: bold;
}

.report-menu .card-panel .report-menu-wrapper img {
    width: auto;
    height: auto;
    margin-right: 0px;
}

.report-menu-details {
    display: flex;
    width: 100%;
    align-items: center;
    column-gap: 15px;
}

.report-menu .card-panel .report-menu-wrapper {
    height: 4rem;
}

.table {
    margin-bottom: 0px;
}

.bonus-history-container .css-tlfecz-indicatorContainer {
    color: #3369FF;
    padding-right: 14px;
}

.custom-select-box .css-g1d714-ValueContainer {
    /* padding-left: 20px; */
    height: 40px;
}

#bonus-history-modern .css-1qyz50w-control {
    height: 40px !important;
}

.card-package .table td:first-child {
    text-align: left;
}

.hoverable {
    color: #3369FF !important;
}

.card-rollover {
    background: linear-gradient(0deg, rgba(25, 35, 47, 0.2), rgba(25, 35, 47, 0.2)), linear-gradient(271.33deg, rgba(78, 75, 200, 0.3) 0%, rgba(254, 94, 171, 0.03) 100%);
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    padding: 15px 20px;
    margin-bottom: 1.5em !important;
    font-size: 12px;
}

.table-rollover tr td, .table-rollover tr th {
    font-size: 12px;
    color: #fff;
    padding: 4px 0;
}

#rollover .table-rollover tr td {
    color: #fff;
}

.table-rollover tr th {
    color: #A4A4A4;
    font-weight: 400;
}

.popup-background-wrapper {
    width: 999vw;
    height: 999vh;
    position: fixed;
    top: 0;
    left: 0;
    background: #00000094;
}

.popup-data-section {
    display: block;
    align-items: unset;
    justify-content: unset;
    position: fixed;
    width: 100%;
    left: 0px;
    bottom: 0px;
    padding: 40px 20px 40px;
    max-width: 500px;
    margin: auto;
    background: #252a5229;
    -webkit-backdrop-filter: blur(35px);
    backdrop-filter: blur(35px);
    border-radius: 65px 65px 0px 0px;
    border: 0px;
    width: 100%;
}

.claim-btn-container {
    display: flex;
    align-items: center;
    column-gap: 15px;
}

    .claim-btn-container > div {
        width: 50%;
    }

.rewards-cancel-btn {
    background: #FF334B;
    box-shadow: 0px 5px 30px rgba(51, 105, 255, 0.7);
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    font-size: 18px;
    font-family: "poppins-semibold";
}

.rewards-confirm-btn {
    background: #3369FF;
    box-shadow: 0px 5px 30px rgba(51, 105, 255, 0.7);
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    font-size: 18px;
    font-family: "poppins-semibold";
}

.reward-info-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.settings-user-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

    .settings-user-container .first {
        width: 62px;
        height: 62px;
    }

    .settings-user-container .title {
        font-size: 20px;
        font-weight: 600;
        color: #fff;
    }

    .settings-user-container .details {
        font-size: 15px;
        color: #898F97;
    }

    .settings-user-container .text {
        font-size: 11px;
        font-weight: bold;
        color: #3369FF;
    }

    .settings-user-container .third {
        text-align: center;
        width: 62px;
    }

.settings-not-verified-container {
    display: flex;
    align-items: center;
    column-gap: 15px;
}

    .settings-not-verified-container .second .title {
        color: #898F97;
        font-size: 11px;
    }

    .settings-not-verified-container .second .details a {
        color: #3369FF;
        font-size: 11px;
    }

.setting-list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 19px 20px;
}

.setting-points-container {
    display: flex;
    align-items: center;
    column-gap: 15px;
    justify-content: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    margin-bottom: 16px;
    padding-bottom: 16px;
}

    .setting-points-container > div {
        display: flex;
        align-items: center;
        column-gap: 10px;
        color: #A4A4A4;
        font-size: 14px;
    }

.setting-verified-box {
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translate(0,-50%);
}

.custom-phone-container .second {
    position: relative;
}

.phone-with-icon .custom-phone-input {
    padding-right: 30px;
}

.input-verified .setting-verified-box {
    right: 20px;
}

.input-verified .custom-input {
    padding-right: 45px;
}

.change-pin-label {
    color: white;
    font-size: 14px;
    font-weight: 500;
    padding-left: 0px;
}

.modal-create-sh-pin {
    background: transparent;
}

    .modal-create-sh-pin .modal-body {
        padding: 40px 20px;
        margin: auto;
        background: radial-gradient(90.16% 143.01% at 15.32% 21.04%, rgba(15, 36, 94, 0.15) 0%, rgba(255, 51, 75, 0.012) 77.08%, rgba(6, 22, 65, 0.15) 100%);
        backdrop-filter: blur(35px);
        border-radius: 65px 65px 0 0;
        border: 0;
        width: 100%;
    }

    .modal-create-sh-pin .keyboard.keyboard-numpad.keyboard-numpad-v3 {
        -webkit-backdrop-filter: blur(35px);
        backdrop-filter: blur(35px);
        padding: 1rem 0 0 0;
        margin-top: 0;
        width: 100%;
        background: transparent;
    }

.pool-result .pool-calendar .react-datepicker-wrapper input {
    background: radial-gradient(90.16% 143.01% at 15.32% 21.04%, rgba(51, 105, 255, 0.2) 0%, rgba(255, 255, 255, 0.0447917) 77.08%, rgba(255, 255, 255, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
    background-blend-mode: overlay, normal;
    -webkit-backdrop-filter: blur(6.07811px);
    backdrop-filter: blur(6.07811px);
    border: 1px solid #cbcbcb26;
    color: #898F97;
    font-size: 12px;
    font-family: "poppins-medium";
    border-radius: 8px !important;
    padding: 0px 20px;
    width: 100%;
}

.pool-result .btn-icon {
    color: #898F97 !important;
}
/* ///4d// */
#result4d .template-box {
    border-radius: 10px;
    margin: 18px 0 30px;
    padding: 0;
}

#result4d .owl-carousel.owl-drag .owl-item-container {
    border-radius: 10px;
    padding: 10px 0;
    margin: 0 30px 0 0;
}

    #result4d .owl-carousel.owl-drag .owl-item-container .media-object {
        width: 55px;
        height: 55px;
        border-radius: 7px;
        margin: auto auto 7px;
    }

#result4d .card-package.card-result {
    background: #fff 0% 0% no-repeat padding-box;
    border-radius: 14px;
    color: #02214a !important;
}

#result4d .pool-name {
    text-transform: uppercase;
    font-size: 17px;
}

#result4d .table {
    color: #fff !important;
}

    #result4d .table .text-white {
        color: #fff !important;
    }

#result4d .card-body table {
    width: 100%;
    text-align: center;
    /* border-collapse: separate; */
    /* border-spacing: 0 20px; */
}

#result4d .banner {
    margin: 0 0 28px;
}

#result4d .table tbody td,
.table tbody th {
    border: none;
}

#result4d .pool-result {
    padding-bottom: 30px;
}

    #result4d .pool-result .pool-calendar {
        position: relative;
        margin: 10px 0 20px;
    }

        #result4d .pool-result .pool-calendar .react-datepicker-wrapper {
            width: 100%;
        }

            #result4d .pool-result .pool-calendar .react-datepicker-wrapper input {
                background: #001A3D 0% 0% no-repeat padding-box;
                border-radius: 10px;
                color: #FFFFFF;
                border: none;
            }

    #result4d .pool-result .card-header {
        border: none;
    }

#result4d {
    margin-left: -7px;
    margin-right: -7px;
    padding-bottom: 4em;
}

    #result4d .result-wrapper {
        border-radius: 14px;
        padding: 13px 10px 10px 10px;
        margin-bottom: 30px;
        margin-left: 7px;
        margin-right: 7px;
    }

    #result4d .result-card-header {
        padding: 0px 0px 9px 15px;
    }

    #result4d .white-background {
        background: #1b234c;
    }

    #result4d .color-wrapper {
        background-color: #FFC159;
    }

    #result4d .draw-date-no-section {
        padding: 8px 15px;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
    }

    #result4d .result-card-body {
        border-radius: 6px;
    }

    #result4d .form-white-search-input {
        padding: 0px 24px 0px 24px;
        font-size: 15px;
        background: transparent linear-gradient(90deg, #113C76 0%, #022556 100%) 0% 0% no-repeat padding-box;
        box-shadow: 3px 4px 6px #00000029;
        border-radius: 15px;
        opacity: 1;
        border: none;
        width: 100%;
        height: 40px;
        color: #FFFFFF;
    }

        #result4d .form-white-search-input::placeholder {
            color: #FFFFFF;
            opacity: 0.6;
        }

    #result4d .result4d-search-icon {
        position: absolute;
        right: 30px;
        top: 8px;
    }

    #result4d .background-container {
        background-color: #02214A;
        padding: 48px 64px;
        border-radius: 10px;
        /* margin-top: 35px;
    margin-bottom:35px; */
        margin-top: 48px;
        margin-bottom: 48px;
    }

    #result4d .title-4dresult {
        font-family: "Roboto", sans-serif;
        color: #fff;
        font-size: 22px;
        font-weight: bold;
    }

    #result4d .prize-type {
        letter-spacing: 0.24px;
        color: #fff;
    }

    #result4d .date-type {
        letter-spacing: 0.24px;
        color: #A4A4A4;
    }

    #result4d .letter-spacing-num {
        letter-spacing: 3.32px;
    }

    #result4d .top-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

.result-slick .slick-dots {
    bottom: 0px;
}

    .result-slick .slick-dots li button:before {
        display: none;
    }

    .result-slick .slick-dots li button {
        background-color: #fff;
        width: 6px;
        height: 6px;
        border-radius: 100%;
        padding: 0px;
    }

    .result-slick .slick-dots li {
        width: unset;
        height: unset;
    }

        .result-slick .slick-dots li.slick-active button {
            background-color: #FFBA33;
            width: 20px;
            border-radius: 6px;
        }
/* end 4d//// */
.modal-create-sh-pin .keyboard.keyboard-numpad.keyboard-numpad-v3 {
    -webkit-backdrop-filter: blur(35px);
    backdrop-filter: blur(35px);
    padding: 1rem 0 0 0;
    margin-top: 0;
    width: 100%;
    background: transparent;
}

.details .member-phone {
    text-align: center;
    background: transparent;
    border: none;
    color: #898F97;
}

    .details .member-phone:focus-visible {
        outline: none;
    }

.react-datepicker__navigation.react-datepicker__navigation--previous {
    display: none;
}

.react-datepicker__navigation.react-datepicker__navigation--next {
    display: none;
}

.claim-limit-reminder {
    font-size: 10px;
}

.modal-content.modal-brand.modal-bottom.modal-numpad .modal-body .display-wrapper .display-value {
    color: white;
}

.modal-content.modal-brand.modal-bottom.modal-numpad .modal-body .display-wrapper .text-brand {
    color: white !important;
}

.modal-content.modal-brand.modal-bottom.modal-numpad .display-wrapper button {
    color: white !important;
}

.modal-content.modal-brand.modal-bottom.modal-numpad {
    background: radial-gradient(90.16% 143.01% at 15.32% 21.04%, rgba(15, 36, 94, 0.15) 0%, rgba(255, 51, 75, 0.012) 77.08%, rgba(6, 22, 65, 0.15) 100%);
    backdrop-filter: blur(35px);
}

#custom-modal .redeem-modal .modal-body {
    padding: 0px;
}

.redeem-modal-image {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 200px;
    background: #fff;
}

.redeem-content {
    padding: 30px 20px;
}

    .redeem-content .title {
        font-size: 20px;
        line-height: 25px;
        color: #fff;
    }

    .redeem-content .points {
        display: flex;
        align-items: center;
        column-gap: 15px;
        color: #3369FF;
        font-size: 12px;
    }

    .redeem-content .details {
        color: #A4A4A4;
        font-size: 12px;
    }

    .redeem-content .redeem-tc a {
        color: #3369FF;
        font-size: 12px;
    }

.sweet-alert {
    background: linear-gradient(0deg, rgb(16 62 183 / 70%) 0%, rgb(181 181 181 / 80%) 100%) !important;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15)) !important;
    border-radius: 30px !important;
}

@media (max-width: 420px) {
    .custom-modal-title {
        font-size: 30px;
    }
}

@media (max-width: 380px) {
    .custom-modal-title {
        font-size: 25px;
    }

  .home-points-list > div {
    width: 35%;
  }

  .home-points-items:last-child > p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 60px;
  }
}

@media (max-width: 340px) {
    .custom-modal-title {
        font-size: 20px;
    }
}

#footer {
    background: #00002080;
    padding: 20px;
    margin: -20px;
    border: none !important;
}

.mobile-footer-content-wrapper {
    display: flex;
    flex-direction: column;
    font-size: 12px;
}

#footer .custom-hr {
    background: white;
    height: 1px;
    width: 70%;
}

.security-icon-list {
    display: flex;
}

#footer .security-icon {
    width: 100px;
    height: 50px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin-right: 20px;
}

.cert-icon-list {
    display: flex;
}

#footer .cert-icon {
    width: 100px;
    height: 50px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin-right: 20px;
}

.payment-method-icon-list {
    display: flex;
}

#footer .payment-method-icon {
    width: 100px;
    height: 50px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.payment-methods-radio .form-checkbox .option.active {
    background: #FFFFFF1A 0% 0% no-repeat padding-box;
    border: 1px solid #3369ff;
    border-radius: 10px;
}

.social-media-icon-list {
    display: flex;
}

#footer .social-media-icon {
    width: 38px;
    height: 60px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.round-icons-list-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.recommended-browser-icon-list {
    display: flex;
    margin-left:3px;
}

#footer .recommended-browser-icon {
    width: 38px;
    height: 60px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

#footer .recommended-browser {
    padding-right: 102px;
}

#footer .copyright-text {
    text-align: center;
    padding: 15px 0 5px 0;
}

#footer .licence-image {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 150px;
    height: 70px;
}



#footer .welcome-msg {
    padding-bottom: 20px;
    font-weight: 400;
    font-size: 12px !important;
    text-align:justify;
}

#footer .custom-vertical-hr {
    width: 1px;
    height: 20px;
    background: white;
    margin: 15px 15px 0px 15px;
}

#footer .recommended-browser .text {
    margin-left: 10px;
}

#footer .social-media .text {
    margin-left: 10px;
}

#footer .version-number {
    text-align: center;
    padding: 15px 0 5px 0;
}




.react-datepicker-wrapper {
    width: 100% !important;
}

.sweet-alert > div div {
    background: transparent !important;
}


.provider-parent-container::-webkit-scrollbar-track {
    /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); */
    background-color: transparent;
    border-radius: 100px;
}

.provider-parent-container::-webkit-scrollbar {
    height: 6px !important;
    background-color: transparent;
    border-radius: 100px;
}

.provider-parent-container::-webkit-scrollbar-thumb {
    background-color: #D9D9D980;
    border-radius: 100px;
}

.mobile-secondary-banner {
    border-radius: 15px;
}

.transfer-wallet-note {
    color: #ffd928;
    margin: 10px 0;
    font-size: 12px;
    font-weight: 400;
}

.invalid-feedback {
    margin-top: 10px !important;
}

.conversion-rate-text {
    font-family: "poppins-regular";
    font-weight: unset;
    font-size: 12px;
    color: #A4A4A4;
}

.provider-logo-div.um {
    filter: grayscale(100%);
}

.provider-spinner {
    text-align: center;
    margin: 20px 0;
}

.provider-item {
    position: relative;
}
.provider-item .um-icon-div {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 55px;
    position: absolute;
    top: 0;
    left: 0;
}

.popular-game-wrapper.um .um-icon {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
}

.popular-game-wrapper.um {
    position: relative;
}

.popular-game-wrapper.um img {
    filter: grayscale(1);
}

.curated-game-wrapper.um {
    position: relative;
}

.curated-game-wrapper.um .um-icon {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
}

.curated-game-wrapper.um img {
    filter: grayscale(1);
}

.nav-title img.img-responsive {
    width: 100px;
}

.card-table .table th, .custom-tables .card-table .table td {
    color: white;
}

.custom-tables .card-table .table th {
    color: white;
}

.alert.alert-warning {
    color: #FFFFFF;
    background: rgba(51, 105, 255, 0.1);
    border: 1px solid rgba(51, 105, 255, 0.8);
}

.css-1av7exa-singleValue {
    padding: 0 !important;
}

.inbox-icon-cont {
    width: 60px;
    height: 60px;
    margin: 0 auto 20px;
    background: linear-gradient(135deg, #1B2A5400, #3369FF99);
    border-radius: 100px;
    padding: 10px;
}

#news-details-modern .news-detail .text-brand {
    font-size: 12px;
}

@media (max-width: 425.9px) {
    .hot-games-tile {
        height: 150px;
    }

    .lobby-games-img {
        height: 115px;
        width: 115px;
    }

    .hot-games-text {
        font-size: 11px;
    }

    .provider-image {
        padding: 22px;
    }
}


@media (max-width: 400.9px) {
    .hot-games-tile {
        height: 135px;
    }

    .lobby-games-img {
        height: 97px;
        width: 97px;
    }

    .provider-image {
        padding: 17px;
    }
}

@media (max-width: 330px) {
    .home-points-items p {
        font-size: 8px;
    }

    .home-wallet-reload {
        padding: 5px 8px 7px 6px !important;
        margin-left: 5px !important;
        font-size: 8px !important;
    }

    .home-wallet-balance div:nth-child(2) > p {
        font-size: 10px;
    }

    .home-button-items .icon .text {
        font-size: 8px !important;
    }

    .hot-games-tile {
        max-width: 90px !important;
        height: 110px;
    }

    .lobby-games-img {
        width: 80px;
        height: 80px;
    }

    .home-game-category-label {
        font-size: 13px;
    }

    .provider-image {
        padding: 13px;
    }
}

.helpcenter-accordion .accordion-button {
    background: #112349;
    border-radius: 8px;
    color: #898F97;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border: 0px;
    padding: 19px 20px;
    font-size: 14px;
}

.helpcenter-accordion .accordion-button[aria-expanded="true"] {
    background: #142655;
    box-shadow: unset;
    border-radius: 8px 8px 0px 0px;
}

.helpcenter-accordion .accordion-body:before {
    display: block;
    content: ' ';
    width: calc(100% - 20px);
    height: 1px;
    background: #898F97;
    margin: auto;
}

.helpcenter-accordion .accordion-item {
    border-radius: 8px;
    background: #142655;
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
}

.helpcenter-accordion h2 {
    margin-bottom: 0px;
}

.helpcenter-data-wrapper {
    display: flex;
    flex-direction: column;
    color: #898F97;
    font-size: 14px;
    font-weight: 500;
    padding: 10px 20px;
}

.helpcenter2-accordion .accordion-button {
    background: #112349;
    border-radius: 8px;
    color: #898F97;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border: 0px;
    padding: 19px 20px;
    font-size: 14px;
}

.helpcenter2-accordion .accordion-button[aria-expanded="true"] {
    background: #142655;
    box-shadow: unset;
    border-radius: 8px;
}

.kpi-select-search {
    position: inherit;
    width: 100%;
    height: 42px;
    left: 17px;
    top: -30px;
    background: radial-gradient(90.16% 143.01% at 15.32% 21.04%, rgba(51, 105, 255, 0.2) 0%, rgba(255, 255, 255, 0.0447917) 77.08%, rgba(255, 255, 255, 0) 200%);
    border-radius: 8px;
    border: .6px solid #898f97;
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
}



.kip-select-backgroud {
    position: inherit;
    width: 100%;
    height: 38.46px;
    left: 17px;
    top: -30px;
}

    .kip-select-backgroud .kip-select-icon {
        position: absolute;
        width: 16.95px;
        height: 17px;
        right: 40px;
        top: 219px;
    }

    .kip-select-backgroud .custom-select-kpi-container {
        position: inherit;
        background: transparent;
        border: 1px;
        outline: unset;
        color: #3369FF;
        opacity: 0.9;
        padding-left: 30px;
        height: 40px;
        -webkit-appearance: none;
        -moz-appearance: none;
        text-indent: 1px;
        text-overflow: '';
        box-sizing: border-box;
        width: 100%
    }
        .kip-select-backgroud .custom-select-kpi-container .kpi-prefix {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 14px;
            color: #3369FF;
            opacity: 0.9;
            background: #15295f;
        }

.pool-result .card-package .card-header {
    color: white;
}

.withdrawal-warning-text img.blue-warning-icon {
    width: 15px;
    height: 15px;
    margin-right: 5px;
    margin-bottom: 3px;
}

.withdrawal-name-alert.alert.alert-warning {
    margin-top: 15px;
}

.game-record-box {
    display:flex;
    column-gap:20px;
}

.game-selected {
   width:50%;
    background: radial-gradient(90.16% 143.01% at 15.32% 21.04%, rgba(51, 105, 255, 0.2) 0%, rgba(255, 255, 255, 0.0447917) 77.08%, rgba(255, 255, 255, 0) 200%);
    border-radius: 8px;
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
}

.game-record-text {

    font-family: 'Montserrat';
    font-style: normal;
    font-size: 12px;
    line-height: 18px;
    padding-left: 20px;
    padding-top: 10px;
    color:white;
}
.game-history .css-16hr6bx-control {
    background: transparent;
    border: 0px;
    border-radius: 8px;
    box-shadow:none !important;
}
.game-history .css-bbcqiw-control {
    background: transparent;
    border: 0px;
    border-radius: 8px;
}
.game-history .css-g1d714-ValueContainer .css-1av7exa-singleValue {
    font-family: 'Montserrat';
    font-style: normal;
    font-size: 12px;
    line-height: 20px;
    color: #3369FF;
    opacity: 1.5;
    
    text-overflow: ellipsis;
}
.game-history .css-1gtu0rj-indicatorContainer {
    color: #3369FF;
}
.game-history .css-tlfecz-indicatorContainer {
    background: transparent;
}
.game-history .css-2b097c-container {
    margin-top: -15px;
}
.game-history .css-2b097c-container .css-26l3qy-menu {
    background: #15295f;
    color: white;
}
.game-history .css-1okebmr-indicatorSeparator {
    background: transparent;
}
.game-history .css-tlfecz-indicatorContainer {
    color: #3369FF;
}

.deposit-record {
    
    background: radial-gradient(90.16% 143.01% at 15.32% 21.04%, rgba(15, 36, 94, 0.15) 0%, rgba(255, 51, 75, 0.012) 77.08%, rgba(6, 22, 65, 0.15) 100%) !important;
    backdrop-filter: blur(35px) !important;
    border-radius: 50px 50px 0px 0px !important;
}

.deposit-icon {
    display: flex;
    flex-direction:column;
    align-items:center;
    padding-top:20px;
}

.deposit-amount-text {
    font-size: 16px;
    color: #002E6C;
    margin-bottom: 30px;
    display: flex;
    justify-content:space-around;
    color:white;
}

.deposit-detail {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: stretch;
    color: white;
}
    .deposit-detail .data {
        display: flex;
        justify-content: space-evenly;
        color: #A4A4A4;
    }

.deposit-button {
    width: 90% !important;
}
.custom-cancel-btn {
    background: #FF334B;
    color: white !important;
}

.keyboard.keyboard-numpad.keyboard-numpad-v3.no-bg {
    background-color: transparent !important;
    border-radius: 0;
    backdrop-filter: unset !important;
}

.referral-share-bg-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(255, 255, 255, 0.02);
    backdrop-filter: blur(5px);
}
.show-country-bg-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

#modal-center .modal-content {
    background: transparent;
    box-shadow: unset;
    max-height: 90%;
}

#modal-center .modal-body {
    padding: 30px 20px 30px;
    max-width: 500px;
    margin: auto;
    background: linear-gradient(0deg, rgb(16 62 183 / 70%) 0%, rgb(181 181 181 / 80%) 100%) !important;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15)) !important;
    border-radius: 30px !important;
    -webkit-backdrop-filter: blur(35px);
    backdrop-filter: blur(35px);
    border-radius: 30px;
    border: 0px;
    width: 100%;
}

.live-chat-box {
    width: 80%;
    position: relative;
    margin: auto;
    text-align: center;
}

.live-chat-img-button {
    width: 75%;
    height: 75%;
}

.live-chat-modal-container {
    background: linear-gradient(0deg, rgba(25, 35, 47, 0.2), rgba(25, 35, 47, 0.2)), linear-gradient(271.33deg, rgba(78, 75, 200, 0.3) 0%, rgba(254, 94, 171, 0.03) 100%);
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
    padding: 15px 12px;
    border-radius: 8px;
    margin-top: 12px !important;
}

#custom-modal3 {
    align-items: flex-end;
    height: 100vh;
    margin: 0 !important;
    text-align: center;
}

    #custom-modal3 .modal-content {
        background: transparent;
        box-shadow: unset;
        max-height: 90%;
        padding-left: 6%;
    }

        #custom-modal3 .modal-content::-webkit-scrollbar {
            display: none;
        }

    #custom-modal3 .modal-body {
        padding: 40px 20px 40px;
        max-width: 500px;
        margin: auto;
        background: radial-gradient(90.16% 143.01% at 15.32% 21.04%, rgba(15, 36, 94, 0.15) 0%, rgba(255, 51, 75, 0.012) 77.08%, rgba(6, 22, 65, 0.15) 100%);
        -webkit-backdrop-filter: blur(35px);
        backdrop-filter: blur(35px);
        border-radius: 20px 20px 20px 20px;
        border: 0px;
        width: 100%;
    }

.live-chat {
    position: fixed;
    left: 88%;
    top: 93%;
    transform: translate(-50%,-50%);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    background: #000000c4;
    backdrop-filter: blur(2px);
    background-color: dodgerblue;
    height: 54px;
    width: 54px;
    border-radius: 50%
}

.modal-bottom-live-chat {
    position: fixed;
    left: 0;
    bottom: 80px;
    max-height: 100%;
    overflow: auto;
    width: 95%;
}

.live-chat-grid {
    display: flex;
    justify-content: space-between;
    background-color: blue;
}

.live-chat-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    width: 300px;
    justify-items: center;
    grid-row-gap: 10px;
}

.live-chat-container .live-chat-item {
    background-color: #f0f0f0;
    padding: 20px;
    text-align: center;
}

.live-chat-gird {
    border-radius: 50%;
    background: linear-gradient(221.94deg, rgba(77, 49, 233, 0.4) 0%, rgba(216, 104, 255, 0) 99.53%);
    width: 48px;
    height: 48px;
    display: grid;
    align-items: center;
    justify-items: center;
}

.live-chat-icon {
    display: grid;
    justify-items: center;
}

.referral-code-link .first > div {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 250px;
}

.gamepoint-voucher-img-box {
  background: linear-gradient(0deg, rgba(25, 35, 47, 0.2), rgba(25, 35, 47, 0.2)), linear-gradient(271.33deg, rgba(78, 75, 200, 0.3) 0%, rgba(254, 94, 171, 0.03) 100%);
  box-shadow: 0px 4px 7px 0px #0000004D;
  border-radius: 8px;
  padding: 15px;
}

.gamepoint-voucher-point-box {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 5px;
  font-size: 10px;
}

  .gamepoint-voucher-point-box .first {
    color: #898F97;
  }

  .gamepoint-voucher-point-box .second {
    color: #3369FF;
  }

.register-steps-container {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
}

  .register-steps-container .step-separator-gray {
    width: 25px;
    height: 2px;
    background: #898F97;
    border-radius: 100px;
    margin-bottom: 5px;
    margin-bottom: 20px;
  }

  .register-steps-container .step-separator-blue {
    width: 25px;
    height: 2px;
    background: #3369FF;
    border-radius: 100px;
    margin-bottom: 5px;
    margin-bottom: 20px;
  }

  .register-steps-container .steps-img {
    width: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .register-steps-container .steps-img div {
    text-wrap: nowrap;
  }

.register-reminder-text {
  font-style: italic;
  font-size: 12px;
}


.or-box {
  display: flex;
  align-items: center;
  column-gap: 10px;
  font-size: 12px;
  color: #898F97;
}

  .or-box .or-line {
    background: #898F97;
    width: 100%;
    height: 1px;
    border-radius: 100px;
  }

.line-btn {
  display: flex;
  align-items: center;
  column-gap: 8px;
  justify-content: center;
  font-size: 12px;
  background: linear-gradient(0deg, rgba(25, 35, 47, 0.5), rgba(25, 35, 47, 0.5)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.4) -18.33%, #1B2A54 100%);
  border-radius: 8px;
  height: 45px;
  position: relative;
}

.line-box {
  border: 1px solid #3369FF;
  padding: 5px;
  border-radius: 8px;
}

.transfer-header-tab-container > .full-width {
    width: 100%;
}


.old-pin-container .pincode-input-container::before,
.new-pin-container .pincode-input-container::before,
.confirm-pin-container .pincode-input-container::before,
.old-sh-pin-container .pincode-input-container::before,
.new-sh-pin-container .pincode-input-container::before,
.confirm-sh-pin-container .pincode-input-container::before {
  content: '';
  display: block;
  width: 89%;
  height: 10%;
  background: transparent;
  position: absolute;
}

#bonus-history .custom-select-box > div > div > div:last-child > span,
#bonus-history-modern .custom-select-box > div > div > div:last-child > span {
  display: none;
}

.kpi-select.custom-select-box > div > div > div {
  height: unset;
}

.kpi-select.custom-select-box > div > div > div:last-child > span {
  display: none;
}

.font-12 {
  font-size: 12px;
}

.font-16 {
  font-size: 16px;
}

.deposit-tutorial-container .css-16hr6bx-control {
    background: radial-gradient(90.16% 143.01% at 15.32% 21.04%, rgba(51, 105, 255, 0.2) 0%, rgba(255, 255, 255, 0.0447917) 77.08%, rgba(255, 255, 255, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
    background-blend-mode: overlay, normal;
    backdrop-filter: blur(6.07811px);
    border: 1px solid #cbcbcb26;
    color: #fff;
    border-radius: 8px;
    height: 60px;
    padding: 0px 20px;
}

.deposit-tutorial-container .css-1okebmr-indicatorSeparator {
    display: none;
}

.deposit-tutorial-container .css-bbcqiw-control {
    background: radial-gradient(90.16% 143.01% at 15.32% 21.04%, rgba(51, 105, 255, 0.2) 0%, rgba(255, 255, 255, 0.0447917) 77.08%, rgba(255, 255, 255, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
    background-blend-mode: overlay, normal;
    backdrop-filter: blur(6.07811px);
    border: 1px solid #cbcbcb26;
    color: #fff;
    border-radius: 8px;
    height: 60px;
    padding: 0px 20px;
}

.deposit-tutorial-container .css-1bxi0x5-singleValue {
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    padding: 0px;
}

.deposit-tutorial-container .css-g1d714-ValueContainer {
    height: 25px;
}

.deposit-tutorial-container .form-group input {
    height: auto;
}

.deposit-tutorial-container .css-1gtu0rj-indicatorContainer {
    padding: 0px;
}

.instant-bank-list {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 20px;
  flex-wrap: wrap;
}



.instant-bank {
  border-radius: 8px;
  display: flex;
  justify-content: center;
  height: 60px;
  width: 120px;
  display: flex;
  align-items: center;
  cursor: pointer;
  background: white;
  position: relative;
}

  .instant-bank .tick-image {
    position: absolute;
    top: -8px;
    right: -10px;
    width: 20px;
  }

  .instant-bank [type=radio] {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }

    /* IMAGE STYLES */
    .instant-bank [type=radio] + img {
      cursor: pointer;
    }

    /* CHECKED STYLES */
    .instant-bank [type=radio]:checked {
      outline: 2px solid #f00;
    }


.instant-bank-list .active {
  box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3), inset 0px 1.5px 0.5px rgba(255, 255, 255, 0.1);
  border: 3px solid #3369FF;
}

.checkmark-title {
  user-select: none;
}

.payment-custom-radio img {
  user-select: none;
}


.deposit-normal-bank-list-container {
  display: grid;
  grid-template-columns: repeat(3,1fr);
  justify-items: center;
  grid-gap: 20px;
  max-width: 700px;
  margin: auto;
}

  .deposit-normal-bank-list-container .deposit-normal-bank-list-items {
    width: 100%;
    background: #D2DEFF;
    align-items: center;
    justify-content: center;
    display: flex;
    height: 50px;
    border-radius: 8px;
    cursor: pointer;
    padding: 10px;
  }

  .deposit-normal-bank-list-container > div.active {
    border: 3px solid #3369FF;
    background: #ABC1FF;
  }

.deposit-normal-bank-details-container {
  padding: 20px;
  background: linear-gradient(0deg, rgba(25, 35, 47, 0.1), rgba(25, 35, 47, 0.1)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%);
  box-shadow: 0px 4px 7px 0px #0000004D;
  border-radius: 8px;
  font-size: 10px;
  color: #fff;
  font-weight: bold;
}

.deposit-normal-bank-details-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

  .deposit-normal-bank-details-item .second {
    display: flex;
    align-items: center;
    column-gap: 10px;
  }

    .deposit-normal-bank-details-item .second .copy {
      width: 20px;
      height: 20px;
      border-radius: 100px;
      background: linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%), linear-gradient(0deg, rgba(51, 105, 255, 0.1), rgba(51, 105, 255, 0.1));
      align-items: center;
      display: flex;
      justify-content: center;
      box-shadow: 0px 4px 7px 0px #0000000D;
      cursor: pointer;
    }

.payment-container .amount-row .custom-button {
  width: 100%;
  background: linear-gradient(0deg, rgba(25, 35, 47, 0.1), rgba(25, 35, 47, 0.1)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%, rgba(27, 42, 84, 0.3) 100%);
  color: #898F97;
  box-shadow: 0px 4px 7px 0px #0000004D;
  border: 0px;
  padding: 8px 0px;
}

.align-items-baseline {
  align-items: baseline;
}

.display-flex {
  display: flex;
}

.normal-parent-bank-title {
  text-align: center;
}

.normal-deposit-remider {
  padding: 15px;
  background: #311e5840;
  border: 1px solid #3369FFCC;
  border-radius: 8px;
}

  .normal-deposit-remider .title {
    color: #3369FF;
  }

  .normal-deposit-remider .details {
    font-size: 10px;
  }

    .normal-deposit-remider .details ol {
      padding-left: 15px;
    }

.normal-bank-selected-img {
  background: #D2DEFF;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 50px;
  border-radius: 8px;
  cursor: pointer;
  width: 100%;
  border: 2px solid #3369FF;
  padding: 10px;
}

.deposit-form .col-form-label {
  font-size: 12px;
}

.normal-bank-tick-selected {
  position: absolute;
  top: -8px;
  right: -5px;
}

.deposit-input-group {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
}

.currency-prefix {
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.5;
  text-align: center;
  white-space: nowrap;
  background: linear-gradient(0deg, rgba(51, 105, 255, 0.1), rgba(51, 105, 255, 0.1)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%);
  padding: 10px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  color: white;
  height: 100%;
  box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
}

  .currency-prefix:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

.deposit-input-group .form-control {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.deposit-input-group input {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.close-button {
  cursor: pointer;
  position: absolute;
  right: 15px;
  padding: 0;
}

.form-control:focus, .form-control:hover {
  box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3) !important;
}

.normal-bank-selected-img > img {
  width: 100%;
}

.new-wallet-balance-text {
  height: 50px;
}

.deposit-normal-bank-details-item .second div:first-child {
  text-align: right;
}

.payment-form .input-with-icon-container input:nth-of-type(2) {
  position: absolute;
  left: 0;
  top: 0;
  box-shadow: none;
}

@media (max-width: 350px) {
  .amount-row {
    grid-template-columns: repeat(2, 1fr);
  }
}


.instant-provider-selected-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 15px;
}


.instant-provider {
  border-radius: 8px;
  display: flex;
  justify-content: center;
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  background: white;
  opacity: 0.7;
  position: relative;
  padding: 0px 10px;
}

  .instant-provider .tick-image {
    position: absolute;
    top: -8px;
    right: -10px;
    width: 20px;
  }

/*.instant-provider-selected-wrapper .active {
  box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3), inset 0px 1.5px 0.5px rgba(255, 255, 255, 0.1);
  border: 3px solid #3369FF;
  opacity: 1;
}*/

.instant-provider-selected-wrapper .active {
  box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3), inset 0px 1.5px 0.5px rgba(255, 255, 255, 0.1);
  border: 3px solid #FFA500;
  opacity: 1;
}

.instant-provider-image {
  max-height: 100%;
  max-width: 100%;
  cursor: pointer;
}

.font-11 {
  font-size: 11px;
}

.tutorial-row {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 3px;
    font-size: 14px;
    color: rgb(164, 164, 164);
}

.tutorial-content-wrapper {
    padding-bottom: 20px;
}

#earned-point-history-modern .form-group,
#earned-point-history .form-group {
  margin-bottom: 0;
}

#earned-point-history-modern .form-group > div > div > div,
#earned-point-history .form-group > div > div > div {
  height: unset;
}

#earned-point-history-modern .form-group > div > div > div:nth-child(2) > div,
#earned-point-history .form-group > div > div > div:nth-child(2) > div {
  height: 40px;
}

#earned-point-history-modern .card-package,
#earned-point-history .card-package {
  margin-top: 1.4rem;
}

#earned-point-history-modern .custom-select-box > div > div > div:nth-child(2) > span,
#earned-point-history .custom-select-box > div > div > div:nth-child(2) > span {
  display: none;
}

.recommended-match-container {
    display: grid;
    align-items: center;
    text-align: center;
    grid-template-columns: 31% 31% 31%;
    justify-content: space-between;
}

    .recommended-match-container .active {
        border: 2px solid dodgerblue;
    }

    .recommended-match-container .recommended-match-items {
        background: linear-gradient(0deg, rgba(25, 35, 47, 0.2), rgba(25, 35, 47, 0.2)), linear-gradient(271.33deg, rgba(78, 75, 200, 0.3) 0%, rgba(254, 94, 171, 0.03) 100%);
        width: 100%;
        min-height: 80px;
        text-align: center;
        box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.1);
        padding: 10px 5px;
        border-radius: 8px;
        font-size: 8px;
        color: white;
        cursor: pointer;
    }

        .recommended-match-container .recommended-match-items .recommended-match-date-time {
            font-size: 10px;
            color: #898F97;
            margin-bottom: 5px;
        }

            .recommended-match-container .recommended-match-items .recommended-match-date-time .dot {
                height: 8px;
                width: 8px;
                background-color: red;
                border-radius: 50%;
                display: inline-block;
                margin-right: 5px;
            }

        .recommended-match-container .recommended-match-items .recommended-match-team {
            font-size: 10px;
            color: white;
            display: grid;
            align-items: center;
            text-align: center;
            grid-template-columns: 30% 60%;
            justify-content: space-around;
            padding: 5px 0px;
        }

            .recommended-match-container .recommended-match-items .recommended-match-team .team-logo img {
                width: 35px;
                height: 35px;
            }

            .recommended-match-container .recommended-match-items .recommended-match-team .team-name {
                text-align: center;
            }

.selected-recommended-match-details {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../../assets/img/v3/selected_recommended_match_background.png");
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    border: 0px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 220px;
}

    .selected-recommended-match-details .topbar {
        font-size: 12px;
        color: white;
        display: grid;
        padding: 10px 20px;
        grid-template-columns: 30% 40% 30%;
    }

        .selected-recommended-match-details .topbar .vendor-logo img {
            width: 70px;
            height: 15px;
        }

        .selected-recommended-match-details .topbar .date-time {
            text-align: center;
        }

        .selected-recommended-match-details .topbar .close-match {
            text-align: end;
            cursor: pointer;
            transition: transform 0.2s ease-in-out;
        }

            .selected-recommended-match-details .topbar .close-match:hover {
                transform: scale(1.1);
            }

    .selected-recommended-match-details .live {
        text-align: center;
    }

        .selected-recommended-match-details .live .dot {
            height: 10px;
            width: 10px;
            background-color: red;
            border-radius: 50%;
            display: inline-block;
            margin-right: 5px;
        }

    .selected-recommended-match-details .match {
        display: grid;
        grid-template-columns: 40% 20% 40%;
        text-align: center;
        margin-top: 15px
    }

        .selected-recommended-match-details .match .team-logo img {
            width: 70px;
            height: 70px;
        }

        .selected-recommended-match-details .match .team-logo .team-name {
            text-align: center;
            font-size: 12px;
            display: block;
            margin-top: 5px;
        }

        .selected-recommended-match-details .match .team-logo .team-score {
            text-align: center;
            font-size: 20px;
            color: #FFD928;
            display: block;
            margin-top: 5px;
        }

        .selected-recommended-match-details .match .versus {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 24px;
        }

.selected-recommended-match-button {
    color: white;
    text-align: center;
    background-color: dodgerblue;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    width: 100%;
    padding: 10px;
    border: 0px;
}

.lobby-games-img .providerTag {
    position: absolute;
    top: -10px;
    left: -5px;
    /* z-index: 998; */
    z-index: 1;
}

.providerTag .img-responsive {
    max-width: 35px;
}

.daily-check-in {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    grid-column-gap: 10px;
}

.daily-coin {
    background: linear-gradient(0deg, rgba(25, 35, 47, 0.2), rgba(25, 35, 47, 0.2)), linear-gradient(271.33deg, rgba(78, 75, 200, 0.3) 0%, rgba(254, 94, 171, 0.03) 100%);
    box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.3);
    width: 100%;
    top: 138px;
    left: 12px;
    border-radius: 8px;
    padding: 10px;
}

.daily-coin-div {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.check-in-big-div {
    width: 100%;
    top: 188px;
    left: 12px;
    border-radius: 8px;
    background: linear-gradient(0deg, rgba(25, 35, 47, 0.2), rgba(25, 35, 47, 0.2)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%);
    box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.3);
    margin-top: 20px;
}

    .check-in-big-div > div {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding: 10px;
    }

.check-in-day {
    margin-top: 20px;
    width: 100%;
    height: 50px;
    top: 210px;
    left: 74px;
    border-radius: 5px;
    border: 1.5px;
    background: linear-gradient(180deg, #B9C0FF -6.16%, #7398FE 86.56%);
    border: 1.5px solid;
    border-image-source: linear-gradient(180deg, #B9C0FF -6.16%, #7398FE 86.56%);
    box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.3);
    position: absolute;
}

.check-in-button {
    width: 100%;
    height: 40px;
    top: 315px;
    left: 22px;
    border-radius: 8px;
    background: rgba(51, 105, 255, 1);
    box-shadow: 0px 5px 15px 0px rgba(51, 105, 255, 0.7);
}

.check-in-text {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: 0px;
    color: rgba(255, 255, 255, 1);
}

.check-in-text-disable {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: 0px;
    color:white;
}

.div-togerther {
    display: flex;
    align-items: center;
    justify-content: center;
}


.div-togerther-1 {
    display: flex;
    align-items: center;
    justify-content: center;
}

.days-text {
    padding-top: 10px;
    font-family: Montserrat;
    font-size: 11px;
    font-weight: 500;
    line-height: 13px;
    letter-spacing: 0px;
    text-align: center;
    color: rgba(178, 178, 178, 1);
}

.days-today-text {
    color: rgba(51, 105, 255, 1) !important;
    font-family: Montserrat;
    font-size: 11px;
    font-weight: 500;
    line-height: 13px;
    letter-spacing: 0px;
    text-align: center;
    align-self: center;
}

.check-in-content-div {
    width: 100%;
    top: 188px;
    left: 12px;
    border-radius: 8px;
    background: linear-gradient(0deg, rgba(25, 35, 47, 0.2), rgba(25, 35, 47, 0.2)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%);
    box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.3);
    margin-top: 20px;
}

    .check-in-content-div > div {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding: 10px;
        font-size: 12px;
    }

.daily-check-in-day {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.gallery-check-in {
    margin: 20px 0px 10px 10px;
    float: left;
    width: 10%;
    background: linear-gradient(221.94deg, rgba(77, 49, 233, 0.4) 0%, rgba(216, 104, 255, 0) 99.53%);
    box-shadow: 0px 1.5px 0.5px 0px rgba(255, 255, 255, 0.1) inset;
    border: 2px solid rgba(51, 105, 255, 1);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 86.95px;
    height: 105px;
    top: 497px;
    left: 624.55px;
    border-radius: 5px;
    position: sticky;
}

    .gallery-check-in img {
        padding-top: 50%;
        width: 40px;
    }

.gallery {
    margin: 20px 0px 10px 10px;
    float: left;
    width: 10%;
    box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.3);
    background: linear-gradient(0deg, rgba(25, 35, 47, 0.2), rgba(25, 35, 47, 0.2)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 86.95px;
    height: 105px;
    top: 497px;
    left: 624.55px;
    border-radius: 5px;
    position: sticky;
}

    .gallery img {
        padding-top: 21%;
        width: 40px;
    }

.gallery-today {
    margin: 20px 0px 10px 10px;
    float: left;
    width: 10%;
    box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.3);
    background: linear-gradient(0deg, rgba(25, 35, 47, 0.2), rgba(25, 35, 47, 0.2)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 86.95px;
    height: 105px;
    top: 497px;
    left: 624.55px;
    border-radius: 5px;
    position: sticky;
    border: 2px solid rgba(51, 105, 255, 1);
}

    .gallery-today img {
        padding-top: 21%;
        width: 40px;
    }

    .gallery-today > .desc-div {
        background: linear-gradient(221.94deg, rgba(77, 49, 233, 0.4) 0%, rgba(216, 104, 255, 0) 99.53%);
        box-shadow: 0px 1.5px 0.5px 0px rgba(255, 255, 255, 0.1) inset;
        width: 100%;
        height: 30px;
        border-radius: 0px 0px 8px 8px;
        color: rgba(51, 105, 255, 1);
        padding: 5px;
        text-align: center;
        margin-block-start: auto;
        font-size: 13px;
    }

.gallery-check-in-today {
    margin: 20px 0px 10px 10px;
    float: left;
    width: 10%;
    background: linear-gradient(221.94deg, rgba(77, 49, 233, 0.4) 0%, rgba(216, 104, 255, 0) 99.53%);
    box-shadow: 0px 1.5px 0.5px 0px rgba(255, 255, 255, 0.1) inset;
    border: 2px solid rgba(51, 105, 255, 1);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 86.95px;
    height: 105px;
    top: 497px;
    left: 624.55px;
    border-radius: 5px;
    position: sticky;
}

    .gallery-check-in-today img {
        padding-top: 50%;
        width: 40px;
    }

    .gallery-check-in-today > .desc-div {
        background: linear-gradient(221.94deg, rgba(77, 49, 233, 0.4) 0%, rgba(216, 104, 255, 0) 99.53%);
        box-shadow: 0px 1.5px 0.5px 0px rgba(255, 255, 255, 0.1) inset;
        width: 100%;
        height: 30px;
        border-radius: 0px 0px 8px 8px;
        color: rgba(51, 105, 255, 1);
        padding: 5px;
        text-align: center;
        margin-block-start: auto;
        font-size: 13px;
    }

.redicon {
    position: absolute;
    background: linear-gradient(90deg, #295EAC 0%, #314F7D 100%);
    box-shadow: 0px 1.5px 0.5px 0px rgba(255, 255, 255, 0.1) inset;
    width: 75%;
    height: 15px;
    border-radius: 8px;
    color: rgba(255, 255, 255, 0.87);
    text-align: center;
    font-size: 11px;
    top: -10px;
}

.redicon-text {
    height: 20px;
    color: rgba(255, 255, 255, 0.87);
    text-align: center;
    margin-block-start: auto;
    font-size: 13px;
}

.desc-div {
    background: linear-gradient(221.94deg, rgba(77, 49, 233, 0.4) 0%, rgba(216, 104, 255, 0) 99.53%);
    box-shadow: 0px 1.5px 0.5px 0px rgba(255, 255, 255, 0.1) inset;
    width: 100%;
    height: 30px;
    border-radius: 0px 0px 8px 8px;
    color: rgba(255, 255, 255, 0.87);
    padding: 5px;
    text-align: center;
    margin-block-start: auto;
    font-size: 13px;
}

.mobile-promo-slider .mobile-promo-banner .mobile-promo-img {
    width: 95%;
    margin: 10px 0;
}

.vip-filter-container {
    display: flex;
    align-items: center;
    overflow-x: scroll;
    padding-bottom: 10px;
}

    .vip-filter-container::-webkit-scrollbar {
        background-color: transparent;
        height: 2px;
    }

    .vip-filter-container::-webkit-scrollbar-thumb {
        background-color: grey;
    }

.vip-level-name {
    background: linear-gradient(0deg, rgba(25, 35, 47, 0.1), rgba(25, 35, 47, 0.1)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%, rgba(27, 42, 84, 0.3) 100%);
    box-shadow: 0px 4px 7px 0px #0000004D;
    padding: 5px;
    border-radius: 30px;
    font-size: 12px;
    width: 100%;
    min-width: 80px;
    text-align: center;
    margin: 0 10px;
}

    .vip-level-name.active {
        background: linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%, rgba(27, 42, 84, 0.3) 100%),linear-gradient(0deg, rgba(51, 105, 255, 0.1), rgba(51, 105, 255, 0.1));
        color: #3369FF;
    }

.promotion-row {
    margin-top: 30px;
}

    .promotion-row .promo-img img {
        width: 100%;
    }

.promo-content {
    margin-top: 10px;
}

#promotion .promo-content .promo-title {
    letter-spacing: 0.4px;
    font-size: 16px;
}

#promotion .promo-content .promo-text {
    margin: 10px 0;
    color: #898F97;
    height: 50px;
    text-overflow: ellipsis;
}

.promo-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

    .promo-btn button {
        min-height: 50px;
        width: 100%;
        border-radius: 10px;
        border: none;
    }

    .promo-btn .btn-1 {
        box-shadow: 0px 5px 30px 0px #3369FFB2;
    }

    .promo-btn .btn-2 {
        margin-left: 10px;
    }

.detail-img img {
    width: 100%;
}

#promotion-detail .detail-title {
    margin-top: 15px;
    font-size: 16px;
    letter-spacing: 0.4px;
}

#promotion-detail .detail-content {
    color: #898F97;
    margin: 10px 0;
}

.loading-promo-spinner-wrapper {
    text-align: center;
    padding: 20px 0;
}

.special-rollover-container {
  background: linear-gradient(0deg, rgba(25, 35, 47, 0.2), rgba(25, 35, 47, 0.2)), linear-gradient(271.33deg, rgba(78, 75, 200, 0.3) 0%, rgba(254, 94, 171, 0.03) 100%);
  box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
  padding: 15px 12px;
  border-radius: 8px;
  margin-top: 12px !important;
}

  .special-rollover-container h1 {
    font-size: 15px;
  }

  .special-rollover-container .icon-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    column-gap: 3px;
  }

  .special-rollover-container .inner-container {
    width: 40px;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }

    .special-rollover-container .inner-container.playable {
      background: linear-gradient(0deg, rgba(25, 35, 47, 0.2), rgba(25, 35, 47, 0.2)), linear-gradient(271.33deg, rgba(78, 75, 200, 0.3) 0%, rgba(254, 94, 171, 0.03) 100%);
      box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
      border-radius: 8px;
      padding: 3px 0;
    }

  .special-rollover-container img {
    width: 25px;
    height: 25px;
  }

  .special-rollover-container .inner-container img {
    opacity: 0.3;
  }

  .special-rollover-container .inner-container.playable img {
    opacity: 1;
  }

  .special-rollover-container .progress-bar-container {
    margin-top: 12px;
  }

  .special-rollover-container .progress-bar-outline {
    padding: 2px;
    height: 100%;
    width: 100%;
    background: #404881;
    border-radius: 5px;
  }

    .special-rollover-container .progress-bar-outline span {
      color: white;
      font-size: 8px;
      left: 48%;
      font-weight: 900;
    }

  .special-rollover-container .progress-bar-fill {
    background: #3369FF;
    border-radius: 5px;
    transition: width 0.5s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 8px;
    height: 12px;
  }

  .special-rollover-container .rollover-text {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
    font-size: 10px;
    column-gap: 3px;
  }

.home-wallet-box-balance {
    display: flex;
    align-items: center;
}

.home-wallet-box-transaction {
    display: flex;
    justify-content: end;
    align-items: center;
}

.home-wallet-check-in {
    background: #3369ff;
    border-radius: 5px;
    padding: 7px 9px;
    font-size: 9px;
    align-items: center;
    line-height: 1;
    cursor: pointer;
    margin-left: 10px;
    position: relative;
}


    .home-wallet-check-in img {
        width: 15px;
    }

    .home-wallet-check-in .red-dot {
        position: absolute;
        top: -3px; /* Adjust the top position */
        right: -3px; /* Adjust the right position */
        width: 10px;
        height: 10px;
        background-color: red;
        border-radius: 50%;
    }

.qr-box-wrapper {
    background: linear-gradient(316.1deg, rgba(51, 105, 255, 0.4) -6.32%, rgba(27, 42, 84, 0) 92.73%);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15));
    border-radius: 5px;
    padding: 5px 8px;
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
    width: 50px;
    height: 45px;
    flex-shrink: 0;
    position: relative;
    margin-left: 5px;
    cursor: pointer;
}

    .qr-box-wrapper img {
        width: 100%;
        height: 100%;
    }
